import React from 'react';
import PropTypes from 'prop-types';
import { graphql, createFragmentContainer } from 'react-relay';

import CartItem from './CartItem';

const CartItemFragment = ({ item, readOnly, delta }) => {
  return React.createElement(
    CartItem,
    {
      item,
      readOnly,
      delta
    }
  );
};


CartItemFragment.defaultProps = {
  readOnly: false,
  delta: 0
};

CartItemFragment.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    qty: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    option: PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired,
  }),
  readOnly: PropTypes.bool,
  delta: PropTypes.number
};

export default createFragmentContainer(
  CartItemFragment,
  {
    item: graphql`
      fragment CartItemFragment_item on CartItem {
        id
        itemId,
        name
        price
        qty
        option {
          title
          value
        }
      }
    `
  }
);
