/**
 * @generated SignedSource<<8f32afa782b3e3f33b8de0f7cc0b86b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Housing",
    "kind": "LinkedField",
    "name": "cmsHousings",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CmsImage",
        "kind": "LinkedField",
        "name": "picture1",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HousingsPageQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HousingsPageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cc3452e0682e8c84bb589418f3f2e5c0",
    "id": null,
    "metadata": {},
    "name": "HousingsPageQuery",
    "operationKind": "query",
    "text": "query HousingsPageQuery {\n  cmsHousings {\n    id\n    slug\n    title\n    picture1 {\n      title\n      url\n    }\n  }\n}\n"
  }
};
})();

node.hash = "c74cb859dbec89e451ed55ba3f3c555b";

module.exports = node;
