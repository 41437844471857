import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { is } from 'ramda';

const CGV = ({
  status,
  onUpdate
}) => {

  const $onUpdate = (e) => {
    e.preventDefault();
    if (is(Function, onUpdate)) onUpdate(!status);
  };

  return (
    <div className="payment-accept-cgv">
      <a
        className="mr-2"
        href="javascript:void(0)"
        onClick={$onUpdate}
      >
        <FontAwesomeIcon style={{ color: '#999' }} size="2x" icon={status ? light('check-circle') : light('circle')} />
      </a>
      <div>
        Merci de nous confirmer que vous acceptez nos&nbsp;
        <span><Link to="/order?modal=cgv">conditions générales de ventes</Link>&nbsp;?</span>
      </div>
    </div>
  );
};

export default CGV;