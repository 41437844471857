import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { is } from 'ramda';

const ProductImage = ({
  item,
  readOnly,
  onRemove
}) => {
  const $onRemove = (e) => {
    e.preventDefault();
    if (is(Function, onRemove)) onRemove();
  };
  return (
    <div
      className="cart-image"
    >
      <img src={`/image/product/${item.id}/100-3-2`} className="lpe-image" />
      {
        !readOnly &&
        <div className="cart-image-remove">
          <a href="javascript:void(0);" onClick={$onRemove}>
            <FontAwesomeIcon icon={light('times')} />
          </a>
        </div>
      }
      <div className="cart-image-qty">
        {item.qty}
      </div>
    </div>
  );
};

export default ProductImage;