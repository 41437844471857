import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, QueryRenderer, useRelayEnvironment } from 'react-relay';
import accounting from 'accounting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';

const CartTopQuery = graphql`
  query CartTopQuery {
    cart {
      id
      nbOfItems
      total {
        id
        productsWithTax
      }
    }
  } 
`;

const Container = styled(Link)`
  
`;
const CartTop = () => {
  const environment = useRelayEnvironment();
  return (
    <Container to={'/cart'}>
      <QueryRenderer
        environment={environment}
        query={CartTopQuery}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            return (
              <div className="cart-top">
                <FontAwesomeIcon className="icon-with-hover-effect" icon={light('shopping-cart')} size="1x" />
                <Link className="cart-top-amount" to={'/cart'}>
                  {accounting.formatMoney(props.cart.total.productsWithTax, '€', 2, ' ', ',', '%v %s')}
                </Link>
              </div>
            );
          }
          return <div>Loading</div>;
        }}
      />
    </Container>
  );
};

export default CartTop;
