/**
 * @generated SignedSource<<8b4c63472e5a06f60babca7d1f559907>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartOrder_total",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithTaxWithoutCoupon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithTax",
      "storageKey": null
    }
  ],
  "type": "CartTotal",
  "abstractKey": null
};

node.hash = "6b40e5e2b76821228c3fb05f6be646ba";

module.exports = node;
