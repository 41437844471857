import React from 'react';
import { graphql, QueryRenderer, useRelayEnvironment } from 'react-relay';
import { Link } from 'react-router-dom';
import BlogArticle from './BlogArticle';
import { Loading } from '../uikit/UIKit';

const ArticleQuery = graphql`
  query BlogPageQuery($id: ID) {
    cmsArticle(id: $id) {
      id
      title
      blocks {
        text
        gallery {
          title
          url
        }
      }
    }
  }
`;

const BlogPage = (props) => {

  const environment = useRelayEnvironment();

  return (
    <div className="blog-article">
      <Link to="/blog">{'liste des articles'}</Link>
      <QueryRenderer
        environment={environment}
        query={ArticleQuery}
        variables={{
          id: props.articleId
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            return <BlogArticle
              title={props.cmsArticle.title}
              blocks={props.cmsArticle.blocks}
            />;
          }
          return <Loading />;
        }}
      />
    </div>
  );
};

export default BlogPage;
