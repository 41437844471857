/**
 * @generated SignedSource<<64fba634f7fa8db294962f36c8e50c8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "active"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "city"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "country"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "extra"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "field1"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "field2"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "field3"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstname"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastname"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "phone"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postcode"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v13 = [
  {
    "kind": "Variable",
    "name": "active",
    "variableName": "active"
  },
  {
    "kind": "Variable",
    "name": "city",
    "variableName": "city"
  },
  {
    "kind": "Variable",
    "name": "companyName",
    "variableName": "companyName"
  },
  {
    "kind": "Variable",
    "name": "country",
    "variableName": "country"
  },
  {
    "kind": "Variable",
    "name": "extra",
    "variableName": "extra"
  },
  {
    "kind": "Variable",
    "name": "field1",
    "variableName": "field1"
  },
  {
    "kind": "Variable",
    "name": "field2",
    "variableName": "field2"
  },
  {
    "kind": "Variable",
    "name": "field3",
    "variableName": "field3"
  },
  {
    "kind": "Variable",
    "name": "firstname",
    "variableName": "firstname"
  },
  {
    "kind": "Variable",
    "name": "lastname",
    "variableName": "lastname"
  },
  {
    "kind": "Variable",
    "name": "phone",
    "variableName": "phone"
  },
  {
    "kind": "Variable",
    "name": "postcode",
    "variableName": "postcode"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingSameAsShipping",
  "storageKey": null
},
v18 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "Address_address"
  }
],
v19 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstname",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastname",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "companyName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "phone",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field1",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field2",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field3",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "city",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "postcode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "country",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "extra",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "active",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "UpdateAddressMutationResponse",
        "kind": "LinkedField",
        "name": "updateAddressMutation",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Addresses",
                "kind": "LinkedField",
                "name": "addresses",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "shipping",
                    "plural": false,
                    "selections": (v18/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "billing",
                    "plural": false,
                    "selections": (v18/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v12/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v2/*: any*/),
      (v10/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v11/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "UpdateAddressMutationResponse",
        "kind": "LinkedField",
        "name": "updateAddressMutation",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Addresses",
                "kind": "LinkedField",
                "name": "addresses",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "shipping",
                    "plural": false,
                    "selections": (v19/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "billing",
                    "plural": false,
                    "selections": (v19/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "19748a19f1a55cf7295ba117bc469931",
    "id": null,
    "metadata": {},
    "name": "UpdateAddressMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAddressMutation(\n  $type: AddressTypeEnum\n  $firstname: String\n  $lastname: String\n  $companyName: String\n  $phone: String\n  $field1: String\n  $field2: String\n  $field3: String\n  $city: String\n  $postcode: String\n  $country: String\n  $extra: String\n  $active: Boolean\n) {\n  updateAddressMutation(type: $type, firstname: $firstname, lastname: $lastname, companyName: $companyName, phone: $phone, field1: $field1, field2: $field2, field3: $field3, city: $city, postcode: $postcode, country: $country, extra: $extra, active: $active) {\n    ok\n    error\n    user {\n      addresses {\n        id\n        billingSameAsShipping\n        shipping {\n          ...Address_address\n        }\n        billing {\n          ...Address_address\n        }\n      }\n    }\n  }\n}\n\nfragment Address_address on Address {\n  firstname\n  lastname\n  companyName\n  phone\n  field1\n  field2\n  field3\n  city\n  postcode\n  country\n  extra\n  active\n}\n"
  }
};
})();

node.hash = "64b93f3efc1b13072f87b79a3aed311c";

module.exports = node;
