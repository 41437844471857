import React from 'react';

import { is } from 'ramda';
import classNames from 'classnames';

const MenuItem = ({
  label,
  level,
  onSelect,
  onRedirect,
  children
}) => {
  const $onSelect = (e) => {
    e.preventDefault();
    if (is(Function, onRedirect)) return onRedirect();
    if (is(Function, onSelect)) return onSelect();
  };

  return (
    <div className="store-menu-l1-item">
      <div
        className={`store-menu-l1-item-label store-menu-item-label-${level}`}
        onClick={$onSelect}>{label}
      </div>
      {children}
    </div >
  );

};

export default MenuItem;