import { isNil, isEmpty } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Redirect
} from 'react-router-dom';

import Description from './Description';
import Pictures from './pictures/Pictures';
import Price from './Price';
import Stock from './Stock';
import Options from './variation/Options';
import Qty from './Qty';
import AddToCart from './AddToCart';

import { Back } from '../uikit/UIKit';

import useProduct from './useProduct';

const Product = ({
  product
}) => {

  const {
    item,
    addingToCart,
    onAdd,
    onUpdateOptionAttributes,
    onUpdateQty,
  } = useProduct(product);

  const { price, stock, qty } = item;
  const { name, description, pictures, variation } = product;

  if (!isNil(product.redirect) && !isEmpty(product.redirect)) return <Redirect to={product.redirect} />;
  return (
    <div>
      <div className="block block-large block-row">
        <div className="product-block-left">
          <Pictures pictures={pictures} />
        </div>
        <div className="block product-block-right">
          <div className="block-title">
            <h2>{name}</h2>
          </div>
          <div className="block-body block-body-center">
            <Price price={price} />
            <Options
              variation={variation}
              onUpdateOptionAttributes={onUpdateOptionAttributes}
            />
            <div style={{ height: '40px' }} />
            <Qty
              qty={qty}
              stock={stock}
              updateQty={onUpdateQty}
            />
            <Stock stock={stock} />
          </div>
          <AddToCart
            style={{ marginTop: '16px' }}
            disabled={stock === 0 || qty === 0}
            addingToCart={addingToCart}
            addToCart={onAdd}
          />
        </div>
      </div>
      <Description style={{ marginTop: '42px' }} description={description} />
      <Back />
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
    name: PropTypes.string,
    redirect: PropTypes.string,
    price: PropTypes.number,
    stock: PropTypes.number,
    pictures: PropTypes.arrayOf(
      PropTypes.shape({
        filename: PropTypes.string
      })
    ),
    variation: PropTypes.shape({
      name: PropTypes.string,
      default: PropTypes.string
    })
  })
};
export default Product;