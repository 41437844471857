import OrderThumb from './OrderThumb';
import React from 'react';

import { isEmpty } from 'ramda';

const OrderList = ({ orders, loadMore }) => {

  if (isEmpty(orders)) {
    return (
      <div>
        {'Vous n\'avez pas encore réalisé de commande'}
      </div>
    );
  }

  return (
    <div>
      {
        orders.map((order) => <OrderThumb key={order.id} order={order} />)
      }
      <div onClick={loadMore}>+</div>
    </div>
  );
};

export default OrderList;
