const R = require('ramda');
import React from 'react';
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import MenuItem from './MenuItem.jsx';

import {
  isEmpty,
  isNil
} from 'ramda';


const MenuContainer = ({
  show,
  categories,
  onHideMenu,
  onRedirect,
  onSelectMenuItem,
  showMenuItemChildren
}) => {

  const $onHideMenu = (e) => {
    e.preventDefault();
    onHideMenu();
  };

  const $onRedirect = (url) => () => {
    onRedirect(url)
  }

  const $onSelectMenuItem = (path) => () => {
    onSelectMenuItem(path);
  };

  const $onSelectMenuAction = (url) => (e) => {
    e.preventDefault();
    onRedirect(url);
  }
  if (!show) return null;

  return (
    <div className="store-menu-container">
      <div className="store-menu">
        <div className="store-menu-menu">
          <div id="left">
            <div onClick={$onSelectMenuAction("/")}>
              <FontAwesomeIcon className="icon-with-hover-effect mr-4" icon={light('home')} size="2x" />
            </div>
            <div onClick={$onSelectMenuAction("/cart")}>
              <FontAwesomeIcon className="icon-with-hover-effect mr-4" icon={light('shopping-cart')} size="2x" />
            </div>
            <div onClick={$onSelectMenuAction("/myaccount")}>
              <FontAwesomeIcon className="icon-with-hover-effect" icon={light('user')} size="2x" />
            </div>
          </div>
          <div id="right" onClick={$onHideMenu}>
            <FontAwesomeIcon className="icon-with-hover-effect" icon={light('xmark')} size="2x" />
          </div>
        </div>
        <div className="store-menu-body">
          {
            !isNil(categories) && categories.map((l1) => {
              let path = [l1.id];
              if (!isNil(l1)) return (
                <MenuItem
                  level={1}
                  key={l1.id}
                  label={l1.name}
                  onSelect={$onSelectMenuItem(path)}
                >
                  {
                    showMenuItemChildren(path) && l1.children.map((l2) => {
                      const path = [l1.id, l2.id];
                      const l2Url = `/products/${l1.url}/${l2.url}`
                      if (!isNil(l2)) return (
                        <MenuItem
                          key={l2.id}
                          level={2}
                          label={l2.name}
                          onRedirect={isEmpty(l2.children) ? $onRedirect(l2Url) : undefined}
                          onSelect={$onSelectMenuItem(path)}
                        >
                          {!isEmpty(l2.children) && showMenuItemChildren(path) && (
                            <>
                              <MenuItem
                                level={3}
                                label="tout voir"
                                path={[l1.id, l2.id, 'all']}
                                onRedirect={$onRedirect(l2Url)}
                              />
                              {
                                l2.children.map((l3) => {
                                  const path = [l1.id, l2.id, l3.id];
                                  const l3Url = `/products/${l1.url}/${l2.url}/${l3.url}`
                                  if (!isNil(l3)) return (
                                    <MenuItem
                                      level={3}
                                      key={l3.id}
                                      label={l3.name}
                                      onRedirect={$onRedirect(l3Url)}
                                    />
                                  );
                                })
                              }
                            </>
                          )}
                        </MenuItem>
                      );
                    })
                  }
                </MenuItem>
              );
            })
          }
        </div>
      </div>
      <div onClick={$onHideMenu} className="flex-1 bg-gray-400/40" />
    </div >
  );
};

export default MenuContainer;