import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Title from './components/Title';
import CartItems from '../cart/CartItems';
import CartTotalFragment from '../cart/CartTotalFragment';
import { Link } from 'react-router-dom';
import { Button } from '../uikit/UIKit';

import { isEmpty } from 'ramda';

const OrderCart = ({
  items,
  total,
  stockDeltas
}) => {

  if (isEmpty(items)) {
    return (
      <div className="block">
        <div className="block-body block-body-center">
          Votre panier est vide
          <Button style={{ marginTop: '16px' }} to="/">Revenir à la boutique</Button>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="block">
        <div className="block-top">
          <Title icon="shopping-cart" value="Détails de votre commande" />
        </div>
        <div className="block-body">
          <Link to="/cart" className="block-action">modifier ma commande</Link>
          <CartItems {...{ items, readOnly: true, stockDeltas }} style={{ marginTop: '32px' }} />
        </div>
      </div>
      <div className="block">
        <div className="block-top">
          <Title icon="shopping-cart" value="Total" />
        </div>
        <div className="block-body">
          <CartTotalFragment {... { total }} style={{ marginTop: '32px' }} />
        </div>
      </div>
    </React.Fragment>
  );
};

OrderCart.propTypes = {
  stockDeltas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.number
    })
  )
};
export default OrderCart;