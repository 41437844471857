
import React from 'react';
import { Link } from 'react-router-dom';
import Markdown from 'react-markdown';

const defaultComponents = {
  a: ({ href, children }) => {
    return <Link to={href}>{children}</Link>;
  }
};

const Mkd = ({
  components = defaultComponents,
  children
}) => {
  return (
    <Markdown
      className="t-mkd"
      components={components}
    >
      {children}
    </Markdown>
  );
};

export default Mkd;