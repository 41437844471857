const R = require('ramda');
import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from '../../uikit/UIKit';
import CountryList from './CountryList';
import Select from 'react-select';
import { useRelayEnvironment } from 'react-relay';
import { useForm, Controller } from 'react-hook-form';

import CreateAddressMutation from '../../../_graphql/mutations/CreateAddressMutation';
import UpdateAddressMutation from '../../../_graphql/mutations/UpdateAddressMutation';

import { applySpec, prop } from 'ramda';

const EditAddress = ({
  addressType,
  create,
  address,
  onClose
}) => {
  const environment = useRelayEnvironment();

  const [fetching, setFetching] = React.useState(false);

  const { control, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: applySpec({
      firstname: prop('firstname'),
      lastname: prop('lastname'),
      companyName: prop('companyName'),
      phone: prop('phone'),
      field1: prop('field1'),
      field2: prop('field2'),
      field3: prop('field3'),
      city: prop('city'),
      postcode: prop('postcode'),
      country: prop('country')
    })(address)
  });

  const $onSubmit = (data) => {

    setFetching(true);
    if (create) {
      return CreateAddressMutation(environment, { ...data, type: addressType }, () => {
        setTimeout(() => {
          setFetching(false);
        }, 200);
      });
    } else {
      return UpdateAddressMutation(environment, { ...data, type: addressType }, () => {
        setTimeout(() => {
          setFetching(false);
        }, 200);
      });
    }
  };

  return (
    <form onSubmit={handleSubmit($onSubmit)}>
      <Controller
        control={control}
        name="firstname"
        render={({ field }) => <Input title="Prénom" {...field} />}
      />
      <Controller
        control={control}
        name="lastname"
        render={({ field }) => <Input title="Nom" {...field} />}
      />
      <Controller
        control={control}
        name="companyName"
        render={({ field }) => <Input title="Société" {...field} />}
      />
      <Controller
        control={control}
        name="field1"
        render={({ field }) => <Input title="Adresse 1" {...field} />}
      />
      <Controller
        control={control}
        name="field2"
        render={({ field }) => <Input title="Adresse 2" {...field} />}
      />
      <Controller
        control={control}
        name="field3"
        render={({ field }) => <Input title="Adresse 3" {...field} />}
      />
      <Controller
        control={control}
        name="city"
        render={({ field }) => <Input title="Ville" {...field} />}
      />
      <Controller
        control={control}
        name="postcode"
        render={({ field }) => <Input title="Code postal" {...field} />}
      />
      <Controller
        control={control}
        name="country"
        render={({ field }) => (
          <Select placeholder="Pays" options={CountryList.get('fr')} simpleValue={true} {...field} />
        )}
      />
      <div>
        <Button
          fetching={fetching}
          style={{ marginTop: '16px' }}
          type="submit"
        >
          {'Enregistrer'}
        </Button>
        {
          !R.isEmpty(address) && <Button onClick={() => onClose()}>Fermer</Button>
        }
      </div>

    </form>
  );

};

EditAddress.EmptyAdress = {
  firstname: '',
  lastname: '',
  companyName: '',
  phone: '',
  field1: '',
  field2: '',
  field3: '',
  city: '',
  postcode: '',
  country: '',
  extra: '',
  active: true
};

EditAddress.propTypes = {
  onClose: PropTypes.func
};

export default EditAddress;