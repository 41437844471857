import React from 'react';
import PropTypes from 'prop-types';

import Address from '../../address/Address';

const ShippingAddress = ({
  address
}) => {
  return (
    <div>
      <div className="order-section-title">Adresse de livraison</div>
      <Address
        {...{ address, type: 'shipping' }}
      />
    </div>
  );
};

ShippingAddress.propTypes = {
  onCountryChange: PropTypes.func
};

export default ShippingAddress;