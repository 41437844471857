import React from 'react';
import { Link } from 'react-router-dom';
import { graphql, QueryRenderer, useRelayEnvironment } from 'react-relay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import classNames from 'classnames';
import {
  isEmpty
} from 'ramda';

const MyAccountTopQuery = graphql`
query MyAccountTopQuery {
    user {
      id
      email
      firstname
    }
} 
`;

const MyAccountTop = ({
  className
}) => {

  const environment = useRelayEnvironment();

  return (
    <QueryRenderer
      environment={environment}
      query={MyAccountTopQuery}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          const { user } = props;
          const to = isEmpty(user.email) ? '/account/signin' : '/myaccount';
          return (
            <div className={classNames('flex items-center', className)}>
              <Link to={to} className="icon-with-hover-effect">
                <FontAwesomeIcon icon={light('user')} size="1x" />
                {!isEmpty(user.firstname) && (
                  <span>&nbsp;{user.firstname}</span>
                )}
              </Link>
            </div>
          );
        }
        return null;
      }}
    />
  );
};

export default MyAccountTop;
