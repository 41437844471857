import { assocPath, lensPath, not, over, path } from 'ramda';
import { hasPath } from 'ramda-adjunct';
import React from 'react';

const useMenu = () => {

  const [state, setState] = React.useState({});

  const onSelectMenuItem = (itemPath) => {
    if (!hasPath(itemPath, state)) return setState(assocPath(itemPath, true));
    return setState(over(lensPath(itemPath), not));
  };

  const showMenuItemChildren = (itemPath) => {
    return path(itemPath, state);
  };

  return {
    showMenuItemChildren,
    onSelectMenuItem,
  };
};

export default useMenu;