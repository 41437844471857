import React from 'react';
import PropTypes from 'prop-types';
import ShippingAddress from './addresses/ShippingAddress';
import BillingAddress from './addresses/BillingAddress';
import { graphql, createFragmentContainer } from 'react-relay';

import { isNil, propOr } from 'ramda';

const OrderAddresses = ({ addresses }) => {
  return (
    <div>
      <ShippingAddress
        address={addresses.shipping}
      />
      {!isNil(addresses.billing) && (
        <BillingAddress
          address={addresses.billing}
          billingSameAsShipping={addresses.billingSameAsShipping}
        />
      )}
    </div>
  );
};


OrderAddresses.propTypes = {
  shipping: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    date: PropTypes.string,
    message: PropTypes.string
  }),
  billing: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    date: PropTypes.string,
  }),
  billingSameAsShipping: PropTypes.bool
};

export default createFragmentContainer(
  OrderAddresses,
  {
    addresses: graphql`
      fragment OrderAddresses_addresses on Addresses {
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
        billingSameAsShipping
      }
    `
  }
);