import {
  commitMutation,
  graphql,
} from 'react-relay';

const mutation = graphql`
  mutation UpdateCartItemQtyMutation($productId: ID, $delta: Int) {
    updateCartItemQtyMutation(productId: $productId, delta: $delta) { 
      ok
      error
      cart {
        id
        items {
          ...CartItemFragment_item
        }
        nbOfItems
        total {
          ...CartTotalFragment_total
        }
      }
    }
  }
`;

export default (environment, { productId, delta }, done) => {
  const variables = {
    productId,
    delta
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (store) => {
      },
      optimisticUpdater: (store) => {
      },
      onCompleted: () => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};
