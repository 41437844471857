import {
  commitMutation,
  graphql,
} from 'react-relay';

const mutation = graphql`
mutation SignOutMutation {
  signOutMutation {
    ok
    error
    user {
      id
      firstname
      lastname
      email
      addresses {
        id
        billingSameAsShipping
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
      }
    }
  }
}
`;

export default (environment, done) => {
  const variables = {

  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.signOutMutation;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
