import React from 'react';
import { graphql, QueryRenderer, useRelayEnvironment } from 'react-relay';

import Order from './Order';

const OrderPageQuery = graphql`
  query OrderPageQuery(
    $id: ID
  ) {
    order(id: $id) {
      id
      date,
      orderRef
      message
      coupon {
        id
        code
        amount
      }

      status {
        ...OrderStatus_status
      }
      total {
        ...OrderTotal_total
      }
      items {
        ...OrderItem_item
      }
      addresses {
        ...OrderAddresses_addresses
      }
    }
  } 
`;

const OrderPage = ({
  orderRef
}) => {

  const environment = useRelayEnvironment();

  return (
    <QueryRenderer
      environment={environment}
      query={OrderPageQuery}
      variables={{
        id: orderRef
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          return (
            <Order {...props.order} />
          );
        }
        return <div>Loading</div>;
      }}
    />
  );
};

export default OrderPage;
