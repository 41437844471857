import React from 'react';
import PropTypes from 'prop-types';
import { graphql, createFragmentContainer } from 'react-relay';
import CartItem from '../cart/CartItem';


const OrderItem = ({ item }) => (
  <CartItem readOnly={true} item={item} />
);

OrderItem.defaultProps = {
  readOnly: false,
  delta: 0
};

OrderItem.propTypes = {
  item: PropTypes.shape({
    qty: PropTypes.number.isRequired,
    optionKey: PropTypes.string.isRequired,
    product: PropTypes.shape({
      option: PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    })
  })
};

export default createFragmentContainer(
  OrderItem,
  {
    item: graphql`
    fragment OrderItem_item on OrderItem {
      id
        name
        qty
        optionKey
        price
        shippingCost
        option {
          name
          value {
            name
          }
        }
      }
    `
  });
