import accounting from 'accounting';
import Moment from 'moment';

const priceToString = (value, currency = '€') => {
  return accounting.formatMoney(value, '€', 2, ' ', ',', '%v %s');
};

const dateToString = (value) => {
  return Moment(value).format('DD/MM/YY HH:mm');
};

export {
  dateToString,
  priceToString
};