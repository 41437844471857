/**
 * @generated SignedSource<<c1d3ea4ea91f81d89771149b170e8d73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Cart",
    "kind": "LinkedField",
    "name": "cart",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nbOfItems",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CartTotal",
        "kind": "LinkedField",
        "name": "total",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productsWithTax",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CartTopQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CartTopQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5ec3ae3befa6639f3e0906dfb70ccbae",
    "id": null,
    "metadata": {},
    "name": "CartTopQuery",
    "operationKind": "query",
    "text": "query CartTopQuery {\n  cart {\n    id\n    nbOfItems\n    total {\n      id\n      productsWithTax\n    }\n  }\n}\n"
  }
};
})();

node.hash = "a2f67ffabc2cc1d25de553cf1f1b0199";

module.exports = node;
