import React from 'react';
import PropTypes from 'prop-types';

import { Motion, spring } from 'react-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import ProductPictureWithZoom from './ProductPictureWithZoom';
import PictureThumbList from './PictureThumbList';
import { isEmpty, length, path } from 'ramda';

const Pictures = ({
  pictures
}) => {

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const onSelectPictureIndex = (index) => {
    setSelectedIndex(index);
  };

  const onSwipeImage = (step) => (e) => {
    e.preventDefault();
    let imageIndex = selectedIndex;
    if (step < 0) {
      if (selectedIndex == 0) {
        imageIndex = pictures.length - 1;
      } else {
        imageIndex--;
      }
    } else {
      if (selectedIndex == pictures.length - 1) {
        imageIndex = 0;
      } else {
        imageIndex++;
      }
    }
    onSelectPictureIndex(imageIndex);
  };

  return (
    <div>
      <Motion defaultStyle={{ opacity: 0 }} style={{ opacity: spring(1) }}>
        {({ opacity }) => (
          <div className="product-pictures" style={{ opacity }}>
            {
              length(pictures) > 0 && (
                <a href="javascript:void(0)" className="navigation" onClick={onSwipeImage(-1)}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </a>
              )
            }
            {
              isEmpty(pictures) &&
              <div style={{ width: '225px', height: '337px', backgroundColor: '#f2f2f2' }} />
            }
            {
              !isEmpty(pictures) &&
              <ProductPictureWithZoom
                normal={`/image/${path([selectedIndex, 'filename'], pictures)}/400-3-2`}
                large={`/image/${path([selectedIndex, 'filename'], pictures)}/800-3-2`}
              />
            }
            {
              length(pictures) > 0 && (
                <a href="javascript:void(0)" className="navigation" onClick={onSwipeImage(+1)}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </a>
              )
            }
          </div>
        )}
      </Motion>
      <PictureThumbList
        pictures={pictures}
        onSelectPictureIndex={onSelectPictureIndex}
      />
    </div>
  );
};

Pictures.propTypes = {
  pictures: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string
    })
  )
};

export default Pictures;
