import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
  compose,
  find,
  length,
  map,
  propEq,
  propOr
} from 'ramda';
import { isNil } from 'lodash';

const Options = ({
  variation,
  onUpdateOptionAttributes
}) => {

  const [selectedOptionKey, setSelectedOptionKey] = React.useState(variation.default);

  const options = React.useMemo(() => {
    return compose(
      map((option) => ({
        value: option.key,
        label: `${variation.name} : ${option.name}`,
      })),
      propOr([], 'options')
    )(variation);
  }, [variation, selectedOptionKey]);

  const hasOptions = React.useCallback(() => {
    return length(options) > 0 && variation.name !== '';
  }, [variation, options]);


  React.useEffect(() => {
    if (hasOptions()) {
      const option = find(propEq('key', selectedOptionKey), variation.options);
      if (isNil(option)) return;
      onUpdateOptionAttributes({
        optionKey: selectedOptionKey,
        price: option.price,
        stock: option.stock
      });
    }
  }, [hasOptions, variation]);

  const $onSelectOption = (optionKey) => {
    setSelectedOptionKey(optionKey);
  };

  if (!hasOptions()) return null;

  return (
    <div className="product-options">
      <Select
        clearable={false}
        placeholder={name}
        searchable={false}
        style={{ width: '350px', textAlign: 'center' }}
        options={options}
        value={selectedOptionKey}
        simpleValue={true}
        onChange={optionKey => $onSelectOption(optionKey)}
      />
    </div>
  );
};

Options.propTypes = {
  variation: PropTypes.shape({
    name: PropTypes.string,
    default: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.number,
        stock: PropTypes.number,
        shippingCost: PropTypes.number
      })
    )
  }),
  updateOptionAttributes: PropTypes.func.isRequired,
};

export default Options;