/* eslint-disable space-before-function-paren */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, QueryRenderer, useRelayEnvironment } from 'react-relay';
import { Redirect, useHistory } from 'react-router-dom';
import { Button } from '../uikit/UIKit';
import OrderListPage from '../orders/OrderListPage';

import useSession from '../../hooks/useSession';
import { isEmpty } from 'ramda';

const MyAccountPageQuery = graphql`
query MyAccountPageQuery {
    user {
      id
      firstname
      lastname
      email
    }
} 
`;

const MyAccountPage = () => {
  const environment = useRelayEnvironment();
  const { onSignOut } = useSession();
  let history = useHistory();

  const $onSignOut = (e) => {
    e.preventDefault();
    onSignOut((ok, error) => {
      if (ok) history.push('/');
    });
  };

  return (
    <QueryRenderer
      environment={environment}
      query={MyAccountPageQuery}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          const { user } = props;
          if (isEmpty(user.email)) return <Redirect to="/" />;
          return (
            <React.Fragment>
              <div className="block">
                <div className="block-title">
                  <h2>Informations sur votre compte</h2>
                </div>
                <div className="block-body">
                  {user.firstname}&nbsp;{user.lastname}
                  <br />
                  email: {user.email}
                  <br />
                  <Button
                    style={{ marginTop: 16 }}
                    onClick={$onSignOut}>
                    Me déconnecter
                  </Button>
                </div>
              </div>
              <div className="block">
                <div className="block-top">
                  <h2>Vos commandes</h2>
                </div>
                <div className="block-body">
                  <OrderListPage />
                </div>
              </div>
            </React.Fragment>
          );
        }
        return null;
      }}
    />
  );
};

export default MyAccountPage;

MyAccountPage.propTypes = {
  user: PropTypes.shape()
};
