import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, createFragmentContainer } from 'react-relay';
import CountryList from './CountryList';

import { isNil } from 'ramda';
const S_Address = styled.div`
  .address-top {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      text-transfrom: uppercase;
      font-size: 11px;
      text-transform: uppercase;
      padding-bottom: 8;
      color: red;
    }
  }
`;

const Address = memo(({
  address
}) => {
  const {
    firstname,
    lastname,
    companyName,
    phone,
    field1,
    field2,
    field3,
    city,
    postcode,
    country
  } = isNil(address) ? {} : address;

  return (
    <S_Address>
      {firstname} {lastname}
      {companyName !== '' && <div>{companyName}</div>}
      {phone !== '' && <div>{phone}</div>}
      {field1 !== '' && <div>{field1}</div>}
      {field2 !== '' && <div>{field2}</div>}
      {field3 !== '' && <div>{field3}</div>}
      <div>{postcode} &nbsp;{city}</div>
      <div>{CountryList.getTitle(country, 'fr')}</div>
    </S_Address>
  );
});

export default Address;