/**
 * @generated SignedSource<<47f496e66e6240b48c4494c08dcc639c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "optionKey"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "price"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "qty"
},
v4 = [
  {
    "kind": "Variable",
    "name": "optionKey",
    "variableName": "optionKey"
  },
  {
    "kind": "Variable",
    "name": "price",
    "variableName": "price"
  },
  {
    "kind": "Variable",
    "name": "productId",
    "variableName": "productId"
  },
  {
    "kind": "Variable",
    "name": "qty",
    "variableName": "qty"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nbOfItems",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productsWithTax",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddToCartMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "AddToCartMutationResponse",
        "kind": "LinkedField",
        "name": "addToCartMutation",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartTotal",
                "kind": "LinkedField",
                "name": "total",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddToCartMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "AddToCartMutationResponse",
        "kind": "LinkedField",
        "name": "addToCartMutation",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartTotal",
                "kind": "LinkedField",
                "name": "total",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6bf8b7dea5169d503796985c35e1e771",
    "id": null,
    "metadata": {},
    "name": "AddToCartMutation",
    "operationKind": "mutation",
    "text": "mutation AddToCartMutation(\n  $productId: String\n  $price: Float\n  $qty: Int\n  $optionKey: String\n) {\n  addToCartMutation(productId: $productId, price: $price, qty: $qty, optionKey: $optionKey) {\n    ok\n    error\n    cart {\n      id\n      nbOfItems\n      total {\n        productsWithTax\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "b7efc6154364b4882c4f12df031606ed";

module.exports = node;
