import React, { Component } from 'react';
import update from 'react-addons-update';
import { Input, Button } from '../uikit/UIKit';
import { Link } from 'react-router-dom';
import qs from 'query-string';

import ResetPasswordMutation from '../../_graphql/mutations/ResetPasswordMutation';

class ResetPassword extends Component {
  constructor() {
    super();

    this.state = {
      password1: '',
      password2: '',
      requestSent: false,
    };
  }

  updateField(fieldname, value) {
    this.setState(update(this.state, { error: { $set: false }, [fieldname]: { $set: value } }));
  }

  submit(e) {
    e.preventDefault();
    const { token } = qs.parse(this.props.location.search);

    ResetPasswordMutation(this.state.password1, token, (ok, error) => {
      if (ok) {
        return this.setState(update(this.state, { requestSent: { $set: true } }));
      } else {
        return this.setState(update(this.state, { error: { $set: true } }));
      }
    });
  }

  isValidPassword() {
    const { password1, password2 } = this.state;

    return (
      (password1 === password2) && password1.length >= 6
    );
  }

  renderError() {
    const { error } = this.state;
    return (
      error &&
      <div className="account-error">
        {'Votre demande n\'a pas pu aboutir'}
      </div>
    );
  }

  render() {
    const { password1, password2, requestSent } = this.state;
    if (!requestSent) {
      return (
        <form className="block" onSubmit={e => this.submit(e)}>
          <div className="block-title">
            <h2>Changer le mot de passe</h2>
          </div>
          <div className="block-body block-body-center">
            <Input error={!this.isValidPassword()} type="password" placeholder="Nouveau mot de passe" value={password1} onChange={v => this.updateField('password1', v)} />
            <Input error={!this.isValidPassword()} type="password" placeholder="Confirmer le mot de passe" value={password2} onChange={v => this.updateField('password2', v)} />
            <Button style={{ marginTop: '24px' }} disabled={!this.isValidPassword()} type="submit">Enregistrer</Button>
            {this.renderError()}
          </div>
        </form>
      );
    } else {
      return (
        <div className="block">
          <div className="block-title">
            <h2>Changer le mot de passe</h2>
          </div>
          <div className="block-body block-body-center">
            <div><b>Votre mot de passe a été modifié.</b></div>
            <br />
            <Link to="/account/signin">Me connecter</Link>
          </div>
        </div>
      );
    }
  }
}

export default ResetPassword;
