import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { is } from 'ramda';

const BtnRemoveOne = ({
  qty,
  disabled,
  onUpdateQty
}) => {
  const $onUpdateQty = (e) => {
    e.preventDefault();
    if (is(Function, onUpdateQty)) onUpdateQty(-1);
  };

  if (disabled || qty === 0) {
    return (
      <span className="icon-disabled">
        <FontAwesomeIcon icon={light('minus-circle')} size="2x" />
      </span>
    );
  } else {
    return (
      <a className="icon-enabled" onClick={$onUpdateQty}>
        <FontAwesomeIcon icon={light('minus-circle')} size="2x" />
      </a>
    );
  }
};

export default BtnRemoveOne;