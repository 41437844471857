
import { path } from 'ramda';

const useOrderStatus = (status, lang = 'fr') => {
  return path([status.state, lang])({
    new: {
      fr: 'Nouvelle',
      en: 'New'
    },
    cancelled: {
      fr: 'Annulée',
      en: 'Cancelled'
    },
    packing: {
      fr: 'En préparation',
      en: 'Packing'
    },
    on_its_way: {
      fr: 'En chemin',
      en: 'On its way'
    },
    closed: {
      fr: 'Fermée',
      en: 'Closed'
    }
  });
};

export default useOrderStatus;