import _ from 'lodash';

class CountryList {
  static get(lang = 'fr') {
    return CountryList.value.map(country => ({
      value: country.code,
      label: country.title[lang]
    }));
  }

  static getTitle(country, lang = 'fr') {
    const countries = _.keyBy(CountryList.value, 'code');
    if (_.isUndefined(countries[country])) return 'FRA';
    return countries[country].title[lang];
  }
}

CountryList.value = [{
  code: 'FRA',
  title: {
    fr: 'France',
    en: 'France'
  }
},
{
  code: 'BEL',
  title: {
    fr: 'Belgique',
    en: 'Belgium'
  }
},
{
  code: 'ESP',
  title: {
    fr: 'Espagne',
    en: 'Spain'
  }
},
{
  code: 'DEU',
  title: {
    fr: 'Allemagne',
    en: 'Germany'
  }
},
{
  code: 'NLD',
  title: {
    fr: 'Hollande',
    en: 'Netherlands'
  }
},
{
  code: 'ITA',
  title: {
    fr: 'Italie',
    en: 'Italy'
  }
},
{
  code: 'LUX',
  title: {
    fr: 'Luxembourg',
    en: 'Luxembourg'
  }
},
{
  code: 'GBR',
  title: {
    fr: 'Royaume Uni',
    en: 'United Kingdom'
  }
},
{
  code: 'CHE',
  title: {
    fr: 'Suisse',
    en: 'Switzerland'
  }
}
];

export default CountryList;