const R = require('ramda');
import { useRelayEnvironment } from 'react-relay';
import AddToCartMutation from '../_graphql/mutations/AddToCartMutation';

const useProduct = () => {
  const environment = useRelayEnvironment();

  const onAddToCart = ({ id, price, qty, optionKey }, cb) => {
    AddToCartMutation(environment, { productId: id, price, qty, optionKey }, () => {
      if (R.is(Function, cb)) cb();
    });
  };

  return {
    onAddToCart
  };
};

export default useProduct;