import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Input } from '../uikit/UIKit';
import { useForm, Controller } from 'react-hook-form';
import useSession from '../../hooks/useSession';

import { isEmpty, propOr } from 'ramda';

const SignIn = () => {

  const { onSignIn } = useSession();
  const { control, handleSubmit } = useForm();
  const [error, setError] = React.useState(false);

  const errorMessage = React.useCallback(() => {
    return propOr(null, error)({
      'PASSWORD_KO': 'Email ou mot de passe incorrect'
    });
  }, [error]);

  const $onSubmit = ({ email, password }) => {
    onSignIn({ email, password }, (ok, error) => {
      if (!ok) setError(error);
    });
  };

  return (
    <form className="block" onSubmit={handleSubmit($onSubmit)}>
      <div className="block-title">
        <h2>{'Me connecter'}</h2>
      </div>
      <div className="block-body block-body-center">
        <Controller
          control={control}
          name="email"
          render={({ field }) => <Input type="email" title="Votre email" {...field} />}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => <Input type="password" title="Votre mot de passe" {...field} />}
        />
        <br />
        <Button type="submit">{'Se connecter'}</Button>
        <Link className="block-link" style={{ marginTop: '8px' }} to="/account/signup">{'Je n\'ai pas de compte'}</Link>
        <Link className="block-link" style={{ marginTop: '8px' }} to="/account/lostpassword">{'J\'ai perdu mon mot de passe'}</Link>
        {!isEmpty(errorMessage()) && (
          <div className="mt-4 text-red">
            {errorMessage()}
          </div>
        )}
      </div>
    </form>
  );
};

export default SignIn;