import React from 'react';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import useSession from '../../hooks/useSession';
import { Button, Input } from '../uikit/UIKit';

import { applySpec, isEmpty, prop, propOr } from 'ramda';

const SignUp = () => {

  const { onSignUp } = useSession();
  const { control, handleSubmit } = useForm();
  const [error, setError] = React.useState('');

  const errorMessage = React.useCallback(() => {
    return propOr(null, error)({
      'USER_DOES_EXIST': 'Un utilisateur est déjà enregistré avec cette adresse email'
    });
  }, [error]);

  const $onSubmit = (data) => {
    onSignUp(applySpec({
      email: prop('email'),
      firstname: prop('firstname'),
      lastname: prop('lastname'),
      password: prop('password1')
    })(data), (ok, error) => {
      if (!ok) setError(error);
    });
  };

  return (
    <form className="block" onSubmit={handleSubmit($onSubmit)}>
      <div className="block-title">
        <h2>Créer mon compte</h2>
      </div>
      <div className="block-body block-body-center">
        <Controller
          control={control}
          name="email"
          render={({ field }) => <Input type="email" title="Votre email" {...field} />}
        />
        <Controller
          control={control}
          name="firstname"
          render={({ field }) => <Input title="Votre prénom" {...field} />}
        />
        <Controller
          control={control}
          name="lastname"
          render={({ field }) => <Input title="Votre nom" {...field} />}
        />
        <Controller
          control={control}
          name="password1"
          render={({ field }) => <Input type="password" title="Votre mot de passe" {...field} />}
        />
        <Controller
          control={control}
          name="password2"
          render={({ field }) => <Input type="password" title="Votre mot de passe (une deuxième fois)" {...field} />}
        />
        <br />
        <Button type="submit" style={{ marginTop: '24px', marginBottom: '16px' }}>
          {'S\'enregistrer'}
        </Button>
        <Link to="/account/signin" style={{ marginTop: '8px' }} className="block-link">{'J\'ai déjà un compte'}</Link>
        {!isEmpty(errorMessage()) && (
          <div className="mt-4 text-red">
            {errorMessage()}
          </div>
        )}
      </div>
    </form>
  );
};

SignUp.Messages = {
  'USER_DOES_EXIST': 'Un utilisateur est déjà enregistré avec cette adresse email'
};

export default SignUp;