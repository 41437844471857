import {
  commitMutation,
  graphql,
} from 'react-relay';

const mutation = graphql`
mutation UpdateCartCountryMutation($country: String) {
  updateCartCountryMutation(country: $country) { 
    ok
    error
    cart {
      total {
        ...CartTotalFragment_total
      }
    }
  }
}
`;

export default (environment, { country }, done) => {
  const variables = {
    country
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: () => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};
