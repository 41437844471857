import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';


const Footer = () => (
  <div className="footer">
    <div className="footer-left">
      <div className="hidden lg:flex">
        les petites emplettes - fournitures générales pour toute la famille
        <div className="mx-2">{'//'}</div>
      </div>
      <a href="/cgv">cgv</a>
    </div>
    <div className="footer-right">
      <a href="https://www.braintreepayments.com" >
        <img className="footer-img" src="/images/braintree-logo.png" />
      </a>
      <a href="https://www.colissimo.entreprise.laposte.fr/fr">
        <img className="footer-img" src="/images/logoColissimo.png" />
      </a>
      <a target="_blank" rel="noopener noreferrer" href="http://www.instagram.com/lespetitesemplettes">
        <FontAwesomeIcon icon={brands('instagram')} size="1x" /></a>
    </div>
  </div >
);

export default Footer;
