/**
 * @generated SignedSource<<25ed271b6c61032a404834947a45735f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "active"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "city"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "country"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "extra"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "field1"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "field2"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "field3"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstname"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastname"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "phone"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postcode"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v13 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "active",
        "variableName": "active"
      },
      {
        "kind": "Variable",
        "name": "city",
        "variableName": "city"
      },
      {
        "kind": "Variable",
        "name": "companyName",
        "variableName": "companyName"
      },
      {
        "kind": "Variable",
        "name": "country",
        "variableName": "country"
      },
      {
        "kind": "Variable",
        "name": "extra",
        "variableName": "extra"
      },
      {
        "kind": "Variable",
        "name": "field1",
        "variableName": "field1"
      },
      {
        "kind": "Variable",
        "name": "field2",
        "variableName": "field2"
      },
      {
        "kind": "Variable",
        "name": "field3",
        "variableName": "field3"
      },
      {
        "kind": "Variable",
        "name": "firstname",
        "variableName": "firstname"
      },
      {
        "kind": "Variable",
        "name": "lastname",
        "variableName": "lastname"
      },
      {
        "kind": "Variable",
        "name": "phone",
        "variableName": "phone"
      },
      {
        "kind": "Variable",
        "name": "postcode",
        "variableName": "postcode"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "CreateAddressMutationResponse",
    "kind": "LinkedField",
    "name": "createAddressMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAddressMutation",
    "selections": (v13/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v12/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v2/*: any*/),
      (v10/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v11/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateAddressMutation",
    "selections": (v13/*: any*/)
  },
  "params": {
    "cacheID": "c9725da36be4eaacf45ca7e7b1388601",
    "id": null,
    "metadata": {},
    "name": "CreateAddressMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAddressMutation(\n  $type: AddressTypeEnum\n  $firstname: String\n  $lastname: String\n  $companyName: String\n  $phone: String\n  $field1: String\n  $field2: String\n  $field3: String\n  $city: String\n  $postcode: String\n  $country: String\n  $extra: String\n  $active: Boolean\n) {\n  createAddressMutation(type: $type, firstname: $firstname, lastname: $lastname, companyName: $companyName, phone: $phone, field1: $field1, field2: $field2, field3: $field3, city: $city, postcode: $postcode, country: $country, extra: $extra, active: $active) {\n    ok\n    error\n  }\n}\n"
  }
};
})();

node.hash = "440cb56670d2f3f40bc61917893d4821";

module.exports = node;
