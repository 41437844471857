import _ from 'lodash';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { graphql, QueryRenderer, useRelayEnvironment } from 'react-relay';

import SignIn from './SignIn';
import SignUp from './SignUp';
import LostPassword from './LostPassword';
import ResetPassword from './ResetPassword';

const AccountQuery = graphql`
query AccountQuery {
  user {
    id
    email
  }
} 
`;

const Account = ({ match }) => {

  const environment = useRelayEnvironment();

  return (
    <QueryRenderer
      environment={environment}
      query={AccountQuery}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          return (
            <div className="account">
              {!_.isEmpty(props.user.email) && <Redirect to="/" />}
              <Route path={`${match.url}/signin`} exact component={SignIn} />
              <Route path={`${match.url}/signup`} exact component={SignUp} />
              <Route path={`${match.url}/lostpassword`} exact component={LostPassword} />
              <Route path={`${match.url}/resetpassword`} exact component={ResetPassword} />
            </div>
          );
        }
        return null;
      }}
    />
  );
};

export default Account;
