const R = require('ramda');
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';

const Button = (props) => {

  const {
    className,
    to,
    size,
    fetching,
    small,
    disabled,
    children
  } = props;

  return React.createElement(
    R.isEmpty(to) ? 'button' : Link,
    {
      ...R.omit(['size', 'fetching', 'small', 'disabled', 'href'], props),
      className: classNames(`${className} t-btn`, {
        't-btn-small': small,
        't-btn-big': size === 'big',
        't-btn-disabled': disabled
      })
    },
    <div>
      {children}
      {
        fetching && <FontAwesomeIcon className="ml-4" icon={faCircleNotch} spin />
      }
    </div>
  );
};

Button.defaultProps = {
  disabled: false,
  href: '',
  to: '',
  type: '',
  className: 't-btn',
  size: 'normal',
  small: false,
  fetching: false,
  onClick: () => { }
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.oneOf(['big', 'normal']),
  small: PropTypes.bool,
  fetching: PropTypes.bool,
  onClick: PropTypes.func
};

export default Button;