import { isNil, isEmpty } from 'ramda';

import React from 'react';
import PropTypes from 'prop-types';
import Description from './Description';
import PicturesMobile from './pictures/PicturesMobile';
import Price from './Price';
import Stock from './Stock';
import Options from './variation/Options';
import Qty from './Qty';
import AddToCart from './AddToCart';
import {
  Redirect
} from 'react-router-dom';
import { Back } from '../uikit/UIKit';
import useProduct from './useProduct';

const Product = ({
  product
}) => {
  const {
    item,
    addingToCart,
    onAdd,
    onUpdateOptionAttributes,
    onUpdateQty,
  } = useProduct(product);

  const { price, stock, qty } = item;
  const { name, description, pictures, variation } = product;

  if (!isNil(product.redirect) && !isEmpty(product.redirect)) return <Redirect to={product.redirect} />;

  return (
    <div className="product-mobile">
      <div>
        <PicturesMobile pictures={pictures} />
      </div>
      <div>
        <h2>{name}</h2>
        <div className="block">
          <Price price={price} />
          <Options
            variation={variation}
            onUpdateOptionAttributes={onUpdateOptionAttributes}
          />
          <div style={{ height: '32px' }} />
          <Qty
            qty={qty}
            stock={stock}
            updateQty={onUpdateQty}
          />
          <Stock stock={stock} />
        </div>
        <AddToCart
          style={{ marginTop: '16px' }}
          disabled={stock === 0 || qty === 0}
          addingToCart={addingToCart}
          addToCart={onAdd}
        />
      </div>
      <Description description={description} />
      <Back />
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    stock: PropTypes.number,
    pictures: PropTypes.arrayOf(
      PropTypes.shape({
        filename: PropTypes.string
      })
    ),
    variation: PropTypes.shape({
      name: PropTypes.string,
      default: PropTypes.string
    })
  })
};
export default Product;