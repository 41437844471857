import {
  commitMutation,
  graphql,
} from 'react-relay';

const mutation = graphql`
mutation UpdateAddressMutation($type: AddressTypeEnum, $firstname: String, $lastname: String, $companyName: String, $phone: String, $field1: String, $field2: String, $field3: String, $city: String, $postcode: String, $country: String, $extra: String, $active: Boolean) {
  updateAddressMutation(type: $type, firstname: $firstname, lastname: $lastname, companyName: $companyName, phone: $phone, field1: $field1, field2: $field2, field3: $field3, city: $city, postcode: $postcode, country: $country, extra: $extra, active: $active) { 
    ok
    error
    user {
      addresses{
        id
        billingSameAsShipping
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
      }
    }
  }
}
`;

export default (environment, {
  firstname,
  lastname,
  companyName,
  phone,
  field1,
  field2,
  field3,
  city,
  postcode,
  country,
  extra,
  active,
  type
}, done) => {
  const variables = {
    firstname,
    lastname,
    companyName,
    phone,
    field1,
    field2,
    field3,
    city,
    postcode,
    country,
    extra,
    active,
    type
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: () => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};
