import React from 'react';
import { Button } from '../uikit/UIKit';
import { useQueryParam, StringParam } from 'use-query-params';

const OrderPaymentError = () => {

  const [error] = useQueryParam('error', StringParam);
  console.error('--------- BRAINTREE PAYMENT ERROR ---------');
  console.error(JSON.stringify(JSON.parse(error), null, 1));
  console.error('-------------------------------------------');

  return (
    < div className="block" >
      <div className="block-body block-body-error">
        <div>
          {'Le paiement n\'a pas pu aboutir, nous sommes désolés'}
          <div className="mt-4 text-xs">
            {JSON.stringify(JSON.parse(error), null, 1)}
          </div>
        </div>
        <Button
          to="/order"
          style={{ marginTop: '16px' }}
        >
          {'Essayer de nouveau'}
        </Button>
      </div>
    </div >
  );
};

export default OrderPaymentError;