import _ from 'lodash';
import {
  commitMutation,
  graphql,
} from 'react-relay';

const mutation = graphql`
  mutation BraintreeMakePaymentMutation($nonce: String, $amount: Float, $message: String) {
    braintreeMakePaymentMutation(nonce: $nonce, amount: $amount, message: $message) {
      status
      cart {
        id
        total {
          id
          ...CartTotalFragment_total
        }
        items {
          ...CartItemFragment_item
        }
      }
      order {
        orderRef
        total {
          id
          productsWithTax
          shippingWithTax          
          totalWithTax
          tax
        }
      }
      stockDeltas {
        id
        value
      }
    }
  }
`;

export default (environment, { nonce, amount, message }, done) => {
  const variables = {
    nonce,
    amount,
    message
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: (response) => {
        const { status, order, stockDeltas } = response.braintreeMakePaymentMutation;
        done({
          order,
          status,
          stockDeltas
        });
      },
      onError: err => console.error(err),
    }
  );
};
