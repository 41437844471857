import {
  commitMutation,
  graphql,
} from 'react-relay';

const mutation = graphql`
  mutation AddToCartMutation($productId: String, $price: Float, $qty: Int, $optionKey: String) {
    addToCartMutation(productId: $productId, price: $price, qty: $qty, optionKey: $optionKey) { 
      ok,
      error,
      cart {
        id
        nbOfItems
        total {
          productsWithTax
        }
      }
    }
  }
`;

export default (environment, { productId, price, qty, optionKey }, done) => {
  const variables = {
    productId,
    price,
    qty,
    optionKey,
    clientMutationId: ''
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (store) => {
        /*
        const root = store.getRootField('addToCart');
        const nbOfItems = root.getValue('nbOfItems');
        const cartTop = store.get('cartTop');
        cartTop.setValue(nbOfItems, 'nbOfItems');
        */
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: () => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};
