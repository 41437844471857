import OrderList from './OrderList';
import React, { useEffect, useState } from 'react';
import { Loading } from '../uikit/UIKit';
import { fetchQuery, graphql, useRelayEnvironment } from 'react-relay';
import * as R from 'ramda';

const OrderListPageQuery = graphql`
  query OrderListPageQuery($filter: OrderFilter) {
    orders(filter: $filter) {
      ...OrderThumb_order
    }
  } 
`;
const OrderListPage = () => {

  const environment = useRelayEnvironment();
  const [data, setData] = useState({
    orders: [],
    pagination: { page: 1, limit: 10 },
  });

  const onFetch = async ({ paginate }) => {
    const { orders } = await fetchQuery(
      environment,
      OrderListPageQuery,
      {
        filter: {}
      }
    ).toPromise();
    const orderList = R.slice(0, paginate.page * paginate.limit, orders);
    setData({
      orders: orderList,
      pagination: {
        total: R.length(orders),
        limit: paginate.limit,
        page: paginate.page,
        pages: Math.round(R.length(orders) / paginate.limit) + 1
      }
    });
  };

  useEffect(() => {
    onFetch({ paginate: { page: 1, limit: 10 } });
  }, []);

  const onloadMore = async () => {
    await onFetch({
      paginate: {
        page: R.path(['pagination', 'page'], data) + 1,
        limit: R.path(['pagination', 'limit'], data)
      }
    });
  };

  if (R.prop('orders', data)) {
    return (
      <OrderList
        orders={R.prop('orders', data)}
        loadMore={onloadMore}
      />
    );
  }
  return <Loading />;
};

export default OrderListPage;
