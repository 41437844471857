import React from 'react';
import AcceptCookies from '../../misc/AcceptCookies';
import Footer from '../footer/Footer';
import Top from './Top';
import MenuLeft from '../../menuLeft/MenuLeft';
import { useHistory } from 'react-router-dom';

const Template = ({
  children
}) => {
  const history = useHistory();

  const [showMenu, setShowMenu] = React.useState();

  const $onHideMenu = () => {
    setShowMenu(false);
  };

  const $onShowMenu = () => {
    setShowMenu(true);
  };

  const $onRedirect = (url) => {
    setShowMenu(false);
    history.push(url);
  };

  React.useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showMenu]);
  return (
    <div className="store-template">
      <MenuLeft
        show={showMenu}
        onHideMenu={$onHideMenu}
        onRedirect={$onRedirect}
      />
      <Top
        onShowMenu={$onShowMenu}
      />
      <div className="template-body">
        <div className="template-main">
          {children}
        </div>
      </div>
      <Footer className="footer" />
      <div className="store-fixed-image-craspedia">
        <img src="/images/craspedia.png" width="200px" />
      </div>
      <AcceptCookies />
    </div>
  );
};

export default Template;