import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isAdmin } from '../../libs';
import { graphql, QueryRenderer, useRelayEnvironment } from 'react-relay';
import { Loading } from '../uikit/UIKit';

import Home from './Home';

const HomePageQuery = graphql`
query HomePageQuery {
  user {
    email
    firstname
  }
  home {
    imageTop {
      url
      title
    }
    imageTopTitle
    aboutTop
    aboutBottom
    thumbs {
      id
      title
      items {
        title
        description
        image {
          url
          title
        }
        link
      }
    }
  }
} 
`;

const HomePage = () => {
  const environment = useRelayEnvironment();
  return (
    <QueryRenderer
      environment={environment}
      query={HomePageQuery}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          return (
            <div className="query-product-list-page">
              <Home
                content={props.home}
              />
            </div>
          );
        }
        return <Loading />;
      }}
    />
  );
};

export default HomePage;
