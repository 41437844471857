import {
  commitMutation,
  graphql,
} from 'react-relay';

const mutation = graphql`
mutation ResetPasswordMutation($password: String, $token: String) {
  resetPasswordMutation(password: $password, token: $token) {
    ok
    error
  }
}
`;

export default (environment, { password, token }, done) => {
  const variables = {
    password,
    token
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.resetPasswordMutation;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
