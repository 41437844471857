
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import Textarea from 'react-autosize-textarea';

import UpdateCategoryMutation from '../../_graphql/mutations/UpdateCategoryMutation';

class ProductListDescription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      description: props.category.description
    };

    this.r = null;
  }

  edit(state) {
    if (!this.props.isAdmin) return null;
    this.setState({ ...this.state, edit: state }, () => {
      if (state) this.r.focus();
    });
  }

  update(e) {
    e.preventDefault();
    this.setState({ ...this.state, description: e.target.value });
  }

  save() {
    const { description } = this.state;
    UpdateCategoryMutation(this.props.category.id, { description }, () => {
      this.edit(false);
    });
  }

  renderDescription() {
    return (
      <div
        style={{ padding: '8px' }}
        onClick={() => this.edit(true)}>
        <Markdown source={this.state.description} />
      </div>
    );
  }
  renderEditDescription() {
    return (
      <Textarea
        innerRef={r => this.r = r}
        className="product-list-description-textarea"
        value={this.state.description}
        onBlur={() => this.save()}
        onChange={(e) => this.update(e)} />
    );
  }

  renderEmptyDescription() {
    if (!this.props.isAdmin) return null;
    return (
      <div
        onClick={() => this.edit(true)}
      >
        cliquer pour ajouter une description
      </div>
    );
  }

  render() {
    const { description, edit } = this.state;
    return (
      <div className="product-list-description">
        {!edit && _.isEmpty(description) && this.renderEmptyDescription()}
        {!edit && !_.isEmpty(description) && this.renderDescription()}
        {edit && this.renderEditDescription()}
      </div>
    );
  }
}

ProductListDescription.defaultProps = {
  description: ''
};

ProductListDescription.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string
  }),
  isAdmin: PropTypes.bool,
};

export default ProductListDescription;