import {
  commitMutation,
  graphql,
} from 'react-relay';

const mutation = graphql`
mutation SignUpMutation($email: String, $firstname: String, $lastname: String, $password: String) {
  signUpMutation(email: $email, firstname: $firstname, lastname: $lastname, password: $password) { 
    ok
    error
    user {
      id
      firstname
      lastname
      email
      addresses {
        id
        billingSameAsShipping
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
      }
    }
  }
}
`;

export default (environment, { email, firstname, lastname, password }, done) => {
  const variables = {
    email,
    firstname,
    lastname,
    password
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => { },
      optimisticUpdater: () => { },
      onCompleted: (response) => {
        const { ok, error } = response.signUpMutation;
        done(ok, error);
      },
      onError: err => {
        console.error(err);
      }
    }
  );
};
