import React from 'react';

const OutOfStock = () => (
  <div>
    {'Votre commande n\'a pas pu aboutir : nos stocks ont évolués et nous ne pouvons satisfaire les quantités demandées.'}
    <br />
    {'Vérifiez les articles surlignés en rouge dans la section \'Détails de votre commande\'.'}
    <br />
    {'Pour finaliser votre commande veuillez ajuster vos quantités.'}
  </div>
);

export default OutOfStock;