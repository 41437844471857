import React, { Component } from 'react';
import update from 'react-addons-update';
import { Input, Button } from '../uikit/UIKit';

import LostPasswordMutation from '../../_graphql/mutations/LostPasswordMutation';

class LostPassword extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
      requestSent: false,
      error: false
    };
  }


  updateField(fieldname, value) {
    this.setState(update(this.state, { error: { $set: false }, [fieldname]: { $set: value } }));
  }

  reset() {
    return this.setState(update(this.state, { error: { $set: false }, requestSent: { $set: false } }));
  }

  submit(e) {
    e.preventDefault();
    LostPasswordMutation(this.state.email, (ok, error) => {
      if (ok) {
        return this.setState(update(this.state, { requestSent: { $set: true } }));
      } else {
        return this.setState(update(this.state, { error: { $set: true } }));
      }
    });
  }

  renderError() {
    const { error } = this.state;
    return (
      error &&
      <div className="account-error">
        {'Votre demande n\'a pu pas aboutir, vérifiez l\'adresse email'}
      </div>
    );
  }

  render() {
    const { email, requestSent } = this.state;

    if (!requestSent) {
      return (
        <form className="block" onSubmit={e => this.submit(e)}>
          <div className="block-title">
            <h2>Mot de passe perdu</h2>
          </div>
          <div className="block-body block-body-center">
            {'Nous allons vous envoyer un lien vous permettant de changer votre mot de passe'}
            <Input type="email" placeholder="votre adresse email" value={email} onChange={v => this.updateField('email', v)} />
            <Button style={{ marginTop: '24px' }} type="submit">Envoyer</Button>
            {this.renderError()}
          </div>
        </form>
      );
    } else {
      return (
        <div className="block">
          <div className="block-title">
            <h2>Mot de passe perdu</h2>
          </div>
          <div className="block-body block-body-center">
            Le lien a été envoyé.
            <br /><br />
            Veuillez vérifier votre boîte mail
            <br /><br /><br />
            <Button onClick={() => this.reset()}>{'Je n\'ai pas reçu le mail'}</Button>
          </div>
        </div>
      );
    }
  }
}

export default LostPassword;