import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import UpdateBillingSameAsShippingMutation from '../../../_graphql/mutations/UpdateBillingSameAsShippingMutation';
import { useRelayEnvironment } from 'react-relay';

const BillingAddressSameAsShipping = ({
  className,
  billingSameAsShipping
}) => {
  const environment = useRelayEnvironment();

  const $onSetSameAsShipping = (e) => {
    e.preventDefault();
    UpdateBillingSameAsShippingMutation(environment, {
      billingSameAsShipping: !billingSameAsShipping
    }, () => {
    });
  };

  return (
    <div className={className}>
      <a
        onClick={$onSetSameAsShipping}
      >
        <FontAwesomeIcon className="mr-2" icon={billingSameAsShipping ? light('square-check') : light('square')} />
        {'L\'adresse de facturation est identique à l\'adresse de livraison'}
      </a>
    </div>
  );
};

export default BillingAddressSameAsShipping;