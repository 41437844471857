import React from 'react';
import { isNil } from 'ramda';

const Option = ({
  item
}) => {
  const getOptionName = () => {
    return item.option.name || item.option.title;
  };

  const getOptionValue = () => {
    if (_.isObject(item.option.value)) {
      return item.option.value.name;
    }
    return item.option.value;
  };

  if (isNil(item.option) || isNil(item.option)) return null;


  return (
    <div className="cart-item-product-option">
      {getOptionName() !== '' ? `${getOptionName()} - ${getOptionValue()}` : ''}
    </div>
  );
};

export default Option;
