import {
  commitMutation,
  graphql,
} from 'react-relay';

const mutation = graphql`
mutation LostPasswordMutation($email: String) {
  lostPasswordMutation(email: $email) {
    ok
    error
  }
}
`;

export default (environment, { email }, done) => {
  const variables = {
    email
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.lostPasswordMutation;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
