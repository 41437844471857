import {
  commitMutation,
  graphql,
} from 'react-relay';

const mutation = graphql`
  mutation RemoveCartItemMutation($itemId: ID) {
    removeCartItemMutation(itemId: $itemId) { 
      ok
      error
      cart {
        id
        items {
          ...CartItemFragment_item
        }
        nbOfItems
        total {
          ...CartTotalFragment_total
        }
      }
    }
  }
`;

export default (environment, { itemId }) => {
  const variables = {
    itemId
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: store => {
      },
      optimisticUpdater: store => {
      },
      onCompleted: () => {
      },
      onError: err => console.error(err),
    }
  );
};
