import _ from 'lodash';
import React from 'react';
import S from 'string';
import { Button, Input } from '../../uikit/UIKit';
import useSession from '../../../hooks/useSession';

import SignUpUserMutation from '../../../_graphql/mutations/SignUpMutation';

import {
  assoc,
  compose,
  isEmpty
} from 'ramda';

const SignUp = () => {

  const { onSignUp } = useSession();

  const [state, setState] = React.useState({
    email: '',
    firstname: '',
    lastname: '',
    password1: '',
    password2: '',
    error: ''
  });


  const enableSubmit = React.useCallback(() => {
    const { password1, password2, firstname, lastname } = state;
    return (
      S(password1).length > 6 &&
      S(password2).length > 6 &&
      password1 === password2 &&
      S(firstname).length > 3 &&
      S(lastname).length > 3
    );
  }, [state]);

  const updateField = (fieldname) => (value) => {
    setState(compose(
      assoc(fieldname, value),
      assoc('error', '')
    ));
  };

  const onSubmit = (e) => {
    console.log('===>', JSON.stringify(state));
    const { email, firstname, lastname, password1 } = state;
    e.preventDefault();
    onSignUp({ email, firstname, lastname, password: password1 }, (ok, error) => {
      console.log('---->', error);
      if (!ok) setState(assoc('error', error));
    });
  };


  return (
    <form onSubmit={onSubmit}>
      <h3>{'Je n\'ai pas de compte'}</h3>
      <Input title="Votre email" value={state.email} onChange={updateField('email')} />
      <Input title="Votre prénom" value={state.firstname} onChange={updateField('firstname')} />
      <Input title="Votre nom" value={state.lastname} onChange={updateField('lastname')} />
      <Input type="password" title="Votre mot de passe" value={state.password1} onChange={updateField('password1')} />
      <Input type="password" title="Votre mot de passe (une deuxième fois)" value={state.password2} onChange={updateField('password2')} />
      <Button type="submit" style={{ marginTop: '16px' }} small={true} disabled={!enableSubmit()}>{'S\'enregistrer'}</Button>
      {
        <div>
          {
            !isEmpty(state.error) &&
            <div className="error">
              {SignUp.Messages[state.error]}
            </div>
          }
        </div>
      }
    </form>
  );
};


SignUp.Messages = {
  'USER_DOES_EXIST': 'Un utilisateur est déjà enregistré avec cette adresse email'
};

export default SignUp;