import React from 'react';
import accounting from 'accounting';
import classNames from 'classnames';

const CartValue = ({
  label,
  value,
  highlight
}) => (
  <div className={classNames('cart-total-value', { 'cart-total-value-bold': highlight })}>
    <div>{label}</div>
    <div id="value">
      {accounting.formatMoney(value, '', 2, ' ', ',')} €
    </div>
  </div>
);

export default CartValue;