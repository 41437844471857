import 'react-select/dist/react-select.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './css/tailwind.css';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import Tokens from './lib/Tokens';
import Routes from './Routes';

import { RelayEnvironmentProvider } from 'react-relay';
import GQLEnvironment from './_graphql/Environment';


Tokens.setKey(process.env.API_USER_ID, process.env.API_KEY);

const getDocumentRoot = () => {
  var element = document.createElement('div');
  document.body.appendChild(element);
  return element;
};

(async () => {
  const environment = await GQLEnvironment();

  ReactDOM.render(
    <RelayEnvironmentProvider environment={environment}>
      <Routes />
    </RelayEnvironmentProvider>,
    getDocumentRoot()
  );
})();
