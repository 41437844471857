import React from 'react';
import { graphql, QueryRenderer, useRelayEnvironment } from 'react-relay';
import { Loading } from '../uikit/UIKit';

import {
  Link
} from 'react-router-dom';

import {
  Housing
} from '.';

const HousingPageQuery = graphql`
  query HousingPageQuery($slug: String) {
    cmsHousing(slug: $slug) {
      id
      title
      description
      meta {
        description
      }
      picture1 {
        title
        url
      }
      picture2 {
        title
        url
      }
      rooms {
        title
        description
        gallery {
          title
          url
        }
      }
      bookingDescription
    }
  }
`;

const HousingPage = ({ slug }) => {
  const environment = useRelayEnvironment();
  return (
    <div className="blog-article">
      <Link to="/housings">{'Nos hébergements'}</Link>
      <QueryRenderer
        environment={environment}
        query={HousingPageQuery}
        variables={{
          slug
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            return <Housing
              value={props.cmsHousing}
            />;
          }
          return <Loading />;
        }}
      />
    </div>
  );
};

export default HousingPage;
