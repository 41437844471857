import './Input.less';
import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
  placeholder,
  title,
  type,
  value,
  onChange
}) => {

  const $onChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <div className="uikit-input">
      {title !== '' && <div>{title}</div>}
      <input
        placeholder={placeholder}
        value={value}
        type={type}
        onChange={$onChange} />
    </div>
  );
};

Input.defaultProps = {
  value: '',
  title: '',
  type: 'text',
  placeholder: '',
  onChange: () => { }
};

Input.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func
};

export default Input;