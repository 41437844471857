import React from 'react';

const PictureThumbList = ({
  pictures,
  onSelectPictureIndex
}) => {

  const $onSelectPictureIndex = (index) => (e) => {
    e.preventDefault();
    onSelectPictureIndex(index);
  };
  return (
    <div className="flex justify-center w-full mt-4">
      {pictures.map(({ filename }, index) => (
        <div
          key={index} 
          className="mx-1 cursor-pointer" 
          onClick={$onSelectPictureIndex(index)}
        >
          <img src={`/image/${filename}/400-3-2`} style={{ height: '60px' }}/>
        </div>
      ))}
    </div>
  );
};

export default PictureThumbList;