import './Order.less';
import React from 'react';
import PropTypes from 'prop-types';
import OrderTotal from './OrderTotal';
import OrderItem from './OrderItem';
import OrderStatus from './OrderStatus';
import OrderAddresses from './OrderAddresses';

import {
  dateToString
} from '../../helpers/Format';

import { isEmpty } from 'ramda';

const Order = ({
  orderRef,
  addresses,
  date,
  items,
  total,
  status,
  message
}) => (
  <React.Fragment>
    <div className="block">
      <div className="block-top">
        <h2>Commande</h2>
      </div>
      <div className="block-body">
        <div className="cart-row">
          N° : {orderRef}
        </div>
        <div className="cart-row">
          Date : le {dateToString(date)}
        </div>
      </div>
    </div>
    <div className="block">
      <div className="block-top">
        <h2>Liste des produits</h2>
      </div>
      <div className="block-body">
        {
          items.map((item, index) => <OrderItem key={index} {...{ item }} />)
        }
      </div>
    </div>
    <div className="block">
      <div className="block-top">
        <h2>Total</h2>
      </div>
      <div className="block-body">
        <OrderTotal total={total} />
      </div>
    </div>
    <div className="block">
      <div className="block-top">
        <h2>Votre message</h2>
      </div>
      <div className="block-body">
        {
          !isEmpty(message) ? message : 'vous n\'avez pas laissé de message'
        }
      </div>
    </div>
    <div className="block">
      <div className="block-top">
        <h2>Adresses</h2>
      </div>
      <div className="block-body">
        <OrderAddresses addresses={addresses} />
      </div>
    </div>
    <div className="block">
      <div className="block-top">
        <h2>Statut de la commande</h2>
      </div>
      <div className="block-body">
        <OrderStatus {...{ status }} />
      </div>
    </div>
  </React.Fragment>
);

Order.propTypes = {
  orderRef: PropTypes.string,
  message: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.object,
  total: PropTypes.object
};

export default Order;