import React from 'react';
import MenuContainer from './MenuContainer';
import PropTypes from 'prop-types';
import { graphql, QueryRenderer, useRelayEnvironment } from 'react-relay';
import useMenu from './useMenu';

const MenuLeftQuery = graphql`
  query MenuLeftQuery {
    categories {
      id
      name
      description
      url 
      redirect
      children {
        id
        name
        description
        url
        redirect
        children {
          name
          description
          url
          redirect
        }
      }
    }
  } 
`;

const MenuLeft = ({
  show,
  onHideMenu,
  onRedirect
}) => {

  const environment = useRelayEnvironment();

  const { onSelectMenuItem, showMenuItemChildren } = useMenu();

  return (
    <QueryRenderer
      environment={environment}
      query={MenuLeftQuery}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          return (
            <MenuContainer
              show={show}
              onHideMenu={onHideMenu}
              onRedirect={onRedirect}
              onSelectMenuItem={onSelectMenuItem}
              showMenuItemChildren={showMenuItemChildren}
              categories={props.categories}
            />
          );
        }
        return null;
      }}
    />
  );
};

MenuLeft.propTypes = {
  fetching: PropTypes.bool.isRequired,
  categoriesFetch: PropTypes.func.isRequired
};

export default MenuLeft;
