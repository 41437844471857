/**
 * @generated SignedSource<<fc4fad4b8927481463a0a39c864dc8be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyAccountPageQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MyAccountPageQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "80767132cd61158b3979cf3f6416fa6d",
    "id": null,
    "metadata": {},
    "name": "MyAccountPageQuery",
    "operationKind": "query",
    "text": "query MyAccountPageQuery {\n  user {\n    id\n    firstname\n    lastname\n    email\n  }\n}\n"
  }
};
})();

node.hash = "d63279d924fec0ae4586b64764b183ce";

module.exports = node;
