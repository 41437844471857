import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Link } from 'react-router-dom';
import useOrderStatus from '../../hooks/useOrderStatus';

import {
  dateToString,
  priceToString
} from '../../helpers/Format';

const OrderThumb = ({
  order
}) => {

  const orderStatus = useOrderStatus(order.status);
  const { id, date, orderRef, total } = order;
  return (
    <div className="order-thumb" to={`/orders/${id}`}>
      <div>{orderRef}</div>
      <div>{dateToString(date)}</div>
      <div>{orderStatus}</div>
      <div>{priceToString(total.totalWithTax)}</div>
      <div>
        <Link to={`/myaccount/orders/${orderRef}`}>
          <FontAwesomeIcon icon={light('eye')} />
        </Link>
      </div>
    </div>
  );
};

OrderThumb.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    date: PropTypes.string,
    orderRef: PropTypes.string,
    status: PropTypes.string,
    total: PropTypes.shape({
      totalWithTax: PropTypes.number
    })
  })
};

export default createFragmentContainer(
  OrderThumb,
  {
    order: graphql`
    fragment OrderThumb_order on Order {
      id,
      orderRef
      date
      status {
        state
      }
      total {
        totalWithTax
      }
    }
  `
  });