import React, { useEffect, useState } from 'react';
import { fetchQuery, graphql, useRelayEnvironment } from 'react-relay';
import CategoryNotFound from './CategoryNotFound';
import ProductList from './ProductList';
import { isAdmin } from '../../libs';
import { Loading } from '../uikit/UIKit';
import * as R from 'ramda';

const ProductListPageQuery = graphql`
  query ProductListPageQuery($filter: ProductFilterInput, $path: String) {
    user {
      id
      email
      firstname
      lastname
    }
    products(filter: $filter) {
      ...ProductThumb_product
    }
    category(path: $path) {
      id
      name
      description        
    }
  } 
`;

const PRODUCT_WIDTH = 230;


const ProductListPage = ({ match }) => {
  const environment = useRelayEnvironment();
  const path = React.useMemo(() => match.params[0], [match.params]);
  const [hasMore, setHasMore] = useState(false);

  const [data, setData] = useState({
    products: null,
    pagination: { page: 1, limit: 16 },
    user: {},
    category: {}
  });

  const onFetch = async ({ path, paginate }) => {
    const { products, user, category } = await fetchQuery(
      environment,
      ProductListPageQuery,
      {
        filter: { path: path },
        path
      }
    ).toPromise();
    const productList = R.slice(0, paginate.page * paginate.limit, products);
    setData({
      products: productList,
      user,
      category,
      pagination: {
        total: R.length(products),
        limit: paginate.limit,
        page: paginate.page,
        pages: Math.round(R.length(products) / paginate.limit) + 1
      }
    });
    setHasMore(productList.length < products.length);
  };

  const onloadMore = async () => {
    await onFetch({
      path,
      paginate: {
        page: R.path(['pagination', 'page'], data) + 1,
        limit: R.path(['pagination', 'limit'], data)
      }
    });
  };

  useEffect(() => {
    setData(R.assoc('products', null));
    onFetch({ path, paginate: { page: 1, limit: 10 } });
  }, [path]);

  if (!R.isNil(R.prop('products', data))) {
    if (R.isEmpty(R.prop('category', data))) {
      return <CategoryNotFound />;
    }
    return (
      <ProductList
        isAdmin={isAdmin(R.path(['user', 'email'], data))}
        pagination={R.prop('pagination', data)}
        category={R.prop('category', data)}
        productWidth={PRODUCT_WIDTH}
        productPerRow={1}
        products={R.prop('products', data)}
        loadMore={onloadMore}
        hasMore={hasMore}
      />
    );
  }
  return (
    <div className="block block-large">
      <div className="block-body">
        <Loading />
      </div>
    </div>
  );
};

const ProductListPageWrapper = (props) => (
  <div className="block block-large">
    <ProductListPage {...props} />
  </div>
);

export default ProductListPageWrapper;
