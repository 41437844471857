import React from 'react';
import { graphql, QueryRenderer, useRelayEnvironment } from 'react-relay';
import url from 'url';

import OrderAccount from './OrderAccount';
import OrderAddresses from './OrderAddresses';
import OrderMessage from './OrderMessage';
import OrderCart from './OrderCart';
import OrderPayment from './OrderPayment';
import OrderShipping from './OrderShipping';
import { Redirect } from 'react-router-dom';
import UpdateCartCountryMutation from '../../_graphql/mutations/UpdateCartCountryMutation';

import { Loading } from '../uikit/UIKit';

import { assoc, isEmpty, isNil } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';

const OrderTunnelPageQuery = graphql`
query OrderTunnelPageQuery {
    user {
      id
      firstname
      lastname
      email
      addresses {
        id
        billingSameAsShipping
        shipping {
          firstname
          lastname
          companyName
          phone
          field1
          field2
          field3
          city
          postcode
          country
          extra
          active
        }
        billing {
          firstname
          lastname
          companyName
          phone
          field1
          field2
          field3
          city
          postcode
          country
          extra
          active
        }
      }
    }
    cart {
      id
      total {
        ...CartTotalFragment_total
        totalWithTax
      }
      items {
        ...CartItemFragment_item
      }
    }
    braintreeToken
} 
`;


const OrderTunnelPage = ({
  orderRef,
  match
}) => {

  const environment = useRelayEnvironment();

  const [state, setState] = React.useState({
    stockDeltas: [], //{ 'id': '5985bbab17cde90aed5d2d4c:p43qxc', 'value': -2 }],
    message: '',
    orderRef: '',
    couponError: false
  });

  const $onSetOrderDone = (orderRef) => {
    setState(assoc('orderRef', orderRef));
  };

  const $onSetOutOfStock = (stockDeltas) => {
    setState(assoc('stockDeltas', stockDeltas));
  };

  const $onSetMessage = (message) => {
    setState(assoc('message', message));
  };

  const $onSetCouponError = () => {
    setState(assoc('couponError', true));
  };

  const $onShippingCountryChange = (country) => {
    UpdateCartCountryMutation(environment, { country }, () => {
    });
  };

  if (!isEmpty(state.orderRef)) return <Redirect to={url.format({ pathname: '/order/success', query: { ref: orderRef } })} />;

  const { couponError, stockDeltas, message } = state;
  return (
    <div>
      <QueryRenderer
        environment={environment}
        query={OrderTunnelPageQuery}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            const { user, cart, braintreeToken } = props;
            return (
              <div>
                {
                  <OrderCart
                    {...{ stockDeltas, items: cart.items, total: cart.total }}
                  />
                }
                {
                  !isEmpty(cart.items) &&
                  <OrderAccount {...{ user }} />
                }
                {
                  !isEmpty(cart.items) && !isNilOrEmpty(user.email) &&
                  <OrderAddresses
                    addresses={user.addresses}
                    onShippingCountryChange={$onShippingCountryChange}
                  />
                }
                {
                  !isEmpty(cart.items) && !isNilOrEmpty(user.email) &&
                  <OrderMessage
                    value={state.message}
                    onUpdate={$onSetMessage}
                  />
                }
                {
                  !isEmpty(cart.items) && !isNilOrEmpty(user.email) && !isNilOrEmpty(user.addresses.shipping) &&
                  <React.Fragment>
                    <OrderPayment
                      {...{ braintreeToken }}
                      amount={cart.total.totalWithTax}
                      message={message}
                      outOfStock={!isEmpty(stockDeltas)}
                      couponError={couponError}
                      onOutOfStock={$onSetOutOfStock}
                      onCouponError={$onSetCouponError}
                      onOrderDone={$onSetOrderDone}
                    />
                    <OrderShipping />
                  </React.Fragment>
                }

              </div>
            );
          }
          return <Loading />;
        }}
      />
    </div>
  );
};

export default OrderTunnelPage;
