import React from 'react';
import accounting from 'accounting';
import classNames from 'classnames';

import CartValue from './cartTotal/Value.jsx';

const CartTotal = ({ total, style }) => {

  const couponValue = total.totalWithTaxWithoutCoupon - total.totalWithTax;
  return (
    <div className="cart-total">
      <CartValue label="Produit(s)" value={total.productsWithTax} />
      <CartValue label="Livraison" value={total.shippingWithTax} />
      {couponValue > 0 && (
        <>
          <CartValue label="Total avant coupon" value={total.totalWithTaxWithoutCoupon} />
          <CartValue label="Coupon de réduction" value={couponValue} />
        </>
      )}
      <CartValue label="Total" value={total.totalWithTax} highlight={true} />
      <div className="text-xs italic text-slate-500">
        (total ht: {accounting.formatMoney(total.totalNoTax, '', 2, ' ', ',')} €, tva: {accounting.formatMoney(total.tax, '', 2, ' ', ',')} €)
      </div>
    </div>
  );
};

export default CartTotal;