import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Title from './components/Title';

import OrderAddress from './addresses/OrderAddress';
import BillingAddressSameAsShipping from './addresses/BillingAddressSameAsShipping';
import { is } from 'ramda';

const OrderAddresses = ({
  addresses,
  onShippingCountryChange
}) => {

  const $onShippingCountryUpdate = (country) => {
    if (is(Function, onShippingCountryChange)) onShippingCountryChange(country);
  };

  return (
    <div className="block">
      <div className="block-top">
        <Title icon="truck" value="Mes adresses" />
      </div>
      <div className="block-body">
        <OrderAddress
          addressType="shipping"
          title="Votre adresse de livraison"
          address={addresses.shipping}
          onCountryChange={$onShippingCountryUpdate}
        />
        <BillingAddressSameAsShipping
          className="my-2"
          billingSameAsShipping={addresses.billingSameAsShipping}
        />
        {!addresses.billingSameAsShipping && (
          <OrderAddress
            addressType="billing"
            title="Votre adresse de facturation"
            address={addresses.billing}
          />
        )}
      </div>
    </div>
  );
};

OrderAddresses.propTypes = {
  onShippingCountryChange: PropTypes.func
};

export default OrderAddresses;
