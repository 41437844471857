import { useRelayEnvironment } from 'react-relay';
import { is } from 'ramda';

import SignInMutation from '../_graphql/mutations/SignInMutation';
import SignOutMutation from '../_graphql/mutations/SignOutMutation';
import SignUpMutation from '../_graphql/mutations/SignUpMutation';

const useSession = () => {
  const environment = useRelayEnvironment();

  const onSignIn = ({ email, password }, cb) => {
    SignInMutation(
      environment,
      { email, password },
      (ok, error) => {
        if (is(Function, cb)) cb(ok, error);
      }
    );
  };

  const onSignOut = (cb) => {
    SignOutMutation(
      environment,
      (ok, error) => {
        if (is(Function, cb)) cb(ok, error);
      }
    );
  };

  const onSignUp = ({ email, password, firstname, lastname }, cb) => {
    SignUpMutation(
      environment,
      { email, password, firstname, lastname },
      (ok, error) => {
        if (is(Function, cb)) cb(ok, error);
      }
    );
  };

  return {
    onSignIn,
    onSignOut,
    onSignUp
  };
};

export default useSession;