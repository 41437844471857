import React from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import accounting from 'accounting';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useHistory } from 'react-router-dom';

import {
  find,
  isNil,
  prop,
  propEq
} from 'ramda';

const ProductThumb = ({
  product
}) => {

  let [imgLoading, setImageLoading] = React.useState(true);
  let history = useHistory();

  const productPrice = () => {
    const { variation, price } = product;
    if (!isNil(variation.default)) {
      const option = find(propEq('key', variation.default), variation.options);
      if (!isNil(option)) {
        return prop('price', option);
      }
    }
    return price;
  };

  const { slug, name, pictures } = product;

  const img = !isNil(pictures[0]) ? pictures[0].filename : 'fake';

  const $onSelect = (e) => {
    e.preventDefault();
    history.push(`/product/${slug}`);
  };

  React.useEffect(() => {
    if (img === 'fake') return;
    const loadImg = new Image();
    loadImg.src = `/image/${img}/400-3-2`;
    loadImg.onload = () => {
      setImageLoading(false);
    };

    loadImg.onerror = err => {
      console.log('image loaded');
    };
  });

  return (
    <div className="product-thumb" onClick={$onSelect}>
      {
        (img === 'fake' || imgLoading) &&
        <div className="lpe-image" style={{ width: '230px', height: '345px', backgroundColor: '#e7e7e7' }} />
      }
      {
        img !== 'fake' && !imgLoading && (
          <img src={`/image/${img}/400-3-2`} width="230px" height="345px" />
        )
      }
      <div className="product-thumb-info">
        <div className="product-thumb-title">
          {name}
        </div>
        <div className="product-thumb-price">
          {accounting.formatMoney(productPrice(), '€', 2, ' ', ',', '%v %s')}
        </div>
      </div>
    </div>
  );
};

ProductThumb.propTypes = {
  name: PropTypes.string,
  pictures: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string
    })
  )
};

export default createFragmentContainer(
  ProductThumb, {
  product:
    graphql`
    fragment ProductThumb_product on Product {
      id,
      slug,
      name,
      price,
      pictures {
        filename
      }
      variation {
        default
        options {
          key
          price
        }
      }
    }
  `
});