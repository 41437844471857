import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CartTop from '../../cart/CartTop';
import MyAccountTop from '../../myAccount/MyAccountTop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const Top = ({
  onShowMenu
}) => {
  const $onShowMenu = (e) => {
    e.preventDefault();
    onShowMenu();
  };
  return (
    <div className="store-top">
      <div className="store-top-left">
        <div className="store-top-menu-icon" onClick={$onShowMenu}>
          <FontAwesomeIcon icon={light('bars')} size="2x" />
        </div>
        <div className="store-top-brand">
          <Link to="/"><h1>les petites emplettes</h1></Link>
        </div>
      </div>
      <div className="store-top-right">
        <CartTop />
        <MyAccountTop className="ml-4" />
      </div>
    </div>
  );
};

Top.defaultProps = {
  isMobile: false
};

Top.PropTypes = {
  isMobile: PropTypes.boolean
};

export default Top;