import React from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import Mkd from '../../components/Mkd';

const Description = ({ style, description }) => {
  return (
    <div className="block block-large" {...{ style }}>
      <div className="block-title">
        <h2>A propos de ce produit</h2>
      </div>
      <div className="block-body">
        <Mkd>
          {description}
        </Mkd>
      </div>
    </div>
  );
};

Description.propTypes = {
  description: PropTypes.string.isRequired,
  style: stylePropType
};

export default Description;