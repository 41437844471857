import { compose, assoc } from 'ramda';
import React from 'react';

const ProductPictureWithZoom = ({
  large,
  normal
}) => {
  const ref = React.useRef(null);
  const [state, setState] = React.useState({
    zoom: false,
    tx: 0,
    ty: 0
  });

  const getTranslateValues = (e) => {
    const offset = ref.current.getBoundingClientRect();
    let x = (e.pageX - offset.left) / 400;
    let y = (e.pageY - offset.top) / 600;
    let tx = -(x * 800 - (e.pageX - offset.left));    
    let ty = -(y * 1200 - (e.pageY - offset.top));
    return ({ tx, ty });
  };


  const $onMove = (e) => {
    let v = getTranslateValues(e);
    setState(compose(
      assoc('tx', v.tx),
      assoc('ty', v.ty)
    ));
  };

  const $onZoomOn = (e) => {
    let v = getTranslateValues(e);
    setState({
      zoom: true,
      ...v
    });
  };

  const $onZoomOff = () => {
    setState(assoc('zoom', false));
  };

  return (
    <div
      className="t-product-picture lpe-image"
      ref={ref}
      onMouseMove={$onMove}
      onMouseEnter={$onZoomOn}
      onMouseLeave={$onZoomOff}
    >
      <img
        alt={normal}
        src={normal}
        className="t-product-picture-no-zoom"
        style={{ display: state.zoom ? 'none' : 'block' }}
      />
      <img
        alt={large}
        src={large}
        className="t-product-picture-zoom"
        style={{
          display: state.zoom ? 'block' : 'none',
          border: '1px solid #444',
          transform: `translate(${state.tx}px, ${state.ty}px)`
        }}
      />
    </div>
  );
};

export default ProductPictureWithZoom;
