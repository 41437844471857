
import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import BraintreeMakePaymentMutation from '../../_graphql/mutations/BraintreeMakePaymentMutation';
import dropin from 'braintree-web-drop-in';
import { Event } from '../../GoogleAnalytics';
import { CGV, OutOfStock, CouponError } from './orderPayment/index.jsx';
import { useRelayEnvironment } from 'react-relay';
import classNames from 'classnames';
import { isNil } from 'ramda';

const OrderPayment = ({
  braintreeToken,
  amount,
  message,
  outOfStock,
  couponError,
  onOrderDone,
  onCouponError,
  onOutOfStock
}) => {

  const [cgvOk, setCgvOk] = React.useState(false);
  const [paymentError, setPaymentError] = React.useState();
  const [braintreeInstance, setBraintreeInstance] = React.useState(null);

  const environment = useRelayEnvironment();

  const $onUpdateCGVStatus = (status) => {
    setCgvOk(status);
  };

  const braintreeContainer = React.useRef();
  React.useEffect(() => {
    dropin.create(
      {
        authorization: braintreeToken,
        container: braintreeContainer.current,
        locale: 'fr_FR',
        threeDSecure: true,

        card: {
          cardholderName: {
            required: true
          }
        }
      },
      (error, braintreeInstance) => {
        if (error) return setPaymentError(error);
        setBraintreeInstance(braintreeInstance);
      }
    );
  }, []);

  const $onMakePayment = (e) => {
    e.preventDefault();
    braintreeInstance.requestPaymentMethod({
      threeDSecure: {
        amount
      }
    }, (err, payload) => {
      console.log(JSON.stringify(payload));
      if (isNil(err)) {
        BraintreeMakePaymentMutation(environment, {
          nonce: payload.nonce, message
        }, ({ status, stockDeltas, order }) => {
          if (status === 'payment_error') {
            setPaymentError(true);
          } else if (status === 'out_of_stock') {
            onOutOfStock(stockDeltas);
          } else if (status === 'coupon_error') {
            onCouponError();
          } else if (status === 'done') {
            Event.transaction(order);
            onOrderDone(order.orderRef);
          }
        });
      } else {
        console.error(err);
      }
    });
  };

  return (
    <div className="order-payment block">
      <div className="block-top">
        <h2>Paiement</h2>
      </div>
      <div className={classNames('block-body', { 'block-body-error': paymentError })}>
        {
          paymentError && <Redirect to={`/order/paymenterror?error=${JSON.stringify(paymentError)}`} />
        }
        {
          !paymentError && outOfStock && <OutOfStock />
        }
        {
          !paymentError && couponError && <CouponError />
        }
        {
          !paymentError && !outOfStock && !couponError &&
          <div className="payment-form">
            <div className="payment-form-text">
              <div className="payment-form-text-title">{'Satisfait ou remboursé !'}</div>
              <div>{'Conformément à la loi vous avez 14 jours après le réglement de votre commande pour nous notifier que vous souhaitez son remboursement total ou partiel (hors frais de port).'}</div>
              <div>{'Suite à cette notification vous aurez 7 jours pour nous retourner les articles (à vos frais).'}</div>
              <div>{'Nous vous remboursons dans un délai de 14 jours après réception de votre retour.'}</div>
              <div className="payment-form-text-title" style={{ marginTop: '32px' }}>{'Partenaire pour le paiement'}</div>
              <div>
                {'Pour le paiement nous faisons confiance à'}
                <img className="ml-2 t-payment-braintree-img" src="/images/braintree-logo.png" />
              </div>
            </div>
            <div className="braintree-area" ref={braintreeContainer} />
            <CGV
              status={cgvOk}
              onUpdate={$onUpdateCGVStatus}
            />
            <button
              className={classNames('t-btn t-btn-big payment-button', { 't-btn-disabled': !cgvOk })}
              onClick={$onMakePayment}
            >
              Payer
            </button>
          </div>
        }
      </div>
    </div>
  );
};

OrderPayment.propTypes = {
  amout: PropTypes.number,
  onOutOfStock: PropTypes.func,
  outOfStock: PropTypes.bool,
  onOrderDone: PropTypes.func,
  onPaymentError: PropTypes.func,
  onCouponError: PropTypes.func
};

export default OrderPayment;