import {
  commitMutation,
  graphql,
} from 'react-relay';

const mutation = graphql`
mutation UpdateCategoryMutation($id: ID, $description: String) {
  updateCategoryMutation(id: $id, description: $description) { 
    ok
    error
  }
}
`;

export default (environment, { id, description }, done) => {
  const variables = {
    id,
    description
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: () => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};
