const R = require('ramda');
import React, { Component } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import CartItems from './CartItems';
import CartTotalFragment from './CartTotalFragment';
import CartOrder from './CartOrder';
import CartCoupon from './CartCoupon'
import { withIsMobile } from '../template/IsMobile';

import { path } from 'ramda'

const CartPageQuery = graphql`
  query CartPageQuery {
    cart {
      id
      coupon {
        id
        code
        amount
      }
      total {
        id
        ...CartTotalFragment_total
        ...CartOrder_total
      }
      items {
        ...CartItemFragment_item
      }
    }
  } 
`;

const Cart = () => {
  const data = useLazyLoadQuery(CartPageQuery, null, { fetchPolicy: 'network-only' });
  const cart = path(["cart"], data)
  const cartCoupon = path(["cart", "coupon"], data)
  return (
    <>
      <div className="block">
        <div className="block-title">
          <h2>Votre panier</h2>
        </div>
        <div className="block-body">
          <CartItems isMobile={true} items={cart.items} />
        </div >
      </div >
      <div className="block">
        <div className="block-title">
          <h2 style={{ marginTop: '24px' }}>Total</h2>
        </div>
        <div className="block-body">
          <CartTotalFragment isMobile={true} total={cart.total} />
        </div >
        <div className="block">
          <div className="block-title">
            <h2 style={{ marginTop: '24px' }}>Coupon de réduction</h2>
          </div>
          <div className="block-body">
            <CartCoupon value={cartCoupon} />
          </div>
        </div>
        <CartOrder total={cart.total} style={{ marginTop: '24px' }} />
      </div >
    </>
  )
}

const CartPage = () => {
  return (
    <React.Suspense fallback={<div />}>
      <Cart />
    </React.Suspense>
  )
}

export default withIsMobile(CartPage);
