import React from 'react';
import PropTypes from 'prop-types';
import Address from './Address';
import EditAddress from './EditAddress';

import { is, not } from 'ramda';


const OrderAddress = ({
  title,
  addressType,
  address,
  onCountryChange
}) => {

  const [editMode, setEditMode] = React.useState(false);

  const $onToggleEditAddress = () => {
    setEditMode(not);
  };

  const $onCountryChange = (country) => {
    if (is(Function, onCountryChange)) onCountryChange(country);
  };


  return (
    <div>
      <h3 className="title-3">
        {title}
        &nbsp;
        (<button className="uppercase underline cursor-pointer text-base" onClick={$onToggleEditAddress}>modifier</button>)
      </h3>
      {!editMode && (
        <Address
          {...{ address, addressType }}
          onCountryChange={$onCountryChange}
        />)
      }
      {editMode && (
        <EditAddress
          addressType={addressType}
          address={address}
          create={false}
          onClose={$onToggleEditAddress}
        />
      )}
    </div>
  );
};

OrderAddress.propTypes = {
  onCountryChange: PropTypes.func
};

export default OrderAddress;