import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useRelayEnvironment } from 'react-relay';
import accounting from 'accounting';


import UpdateCartItemQtyMutation from '../../_graphql/mutations/UpdateCartItemQtyMutation';
import RemoveCartItemMutation from '../../_graphql/mutations/RemoveCartItemMutation';
import BtnAddOne from './cartItem/BtnAddOne';
import BtnRemoveOne from './cartItem/BtnRemoveOne';
import Option from './cartItem/Option';
import ProductImage from './cartItem/ProductImage';
import { assoc, compose, isNil } from 'ramda';
import OutOfStock from './cartItem/OutOffStock';

const CartItem = ({
  delta,
  item,
  readOnly
}) => {
  const environment = useRelayEnvironment();

  const [state, setState] = React.useState({
    addOneIsDisabled: false,
    removeOneIsDisabled: false,
    removeItemIsDisabled: false,
    showRemove: false
  });

  const $onUpdateQty = (delta) => {
    const { id } = item;
    setState(compose(
      assoc('addOneIsDisabled', delta === 1),
      assoc('removeOneIsDisabled', delta === -1)
    ));
    UpdateCartItemQtyMutation(environment, { productId: id, delta }, () => {
      setState(compose(
        assoc('addOneIsDisabled', false),
        assoc('removeOneIsDisabled', false)
      ));
    });
  };

  const $onRemove = () => {
    const { itemId } = item;
    setState(compose(
      assoc('addOneIsDisabled', true),
      assoc('removeOneIsDisabled', true),
      assoc('removeItemIsDisabled', true)
    ));

    RemoveCartItemMutation(environment, { itemId }, () => {

    });
  };

  if (isNil(item)) return null;

  const { id, name, qty, price, option } = item;

  return (
    <div className="cart-item">
      <div className="cart-item-image">
        <div>
          {!readOnly && (
            <BtnRemoveOne
              qty={item.qty}
              disabled={state.removeItemIsDisabled}
              onUpdateQty={$onUpdateQty}
            />
          )}
        </div>
        <ProductImage
          item={item}
          readOnly={readOnly}
          onRemove={$onRemove}
        />
        <div>
          {!readOnly && (
            <BtnAddOne
              disabled={state.addOneIsDisabled}
              onUpdateQty={$onUpdateQty}
            />
          )}
        </div>
      </div>
      <div>
        <Link className="cart-item-product-title" to={`/product/${id}`}>{name}</Link>
        <Option item={item} />
      </div>
      <div className="cart-item-product-price">
        {accounting.formatMoney(price, '', 2, ' ', ',')}&nbsp;€
      </div>
      {readOnly && (delta !== 0) && (
        <OutOfStock
          item={item}
          delta={delta}
        />
      )}
    </div>
  );
};

CartItem.defaultProps = {
  readOnly: false,
  delta: 0
};

CartItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    qty: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    option: PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired,
  }),
  readOnly: PropTypes.bool,
  delta: PropTypes.number
};

export default CartItem;
