import React from 'react';

const OutOfStock = ({
  item,
  delta
}) => {
  return (
    <div className="cart-item-message-out-of-stock">
      {
        item.qty + delta === 0 ?
          'Ce produit n\'est plus en stock' :
          `Seulement ${item.qty + delta} exemplaire(s) en stock`
      }
    </div>
  );
};

export default OutOfStock;