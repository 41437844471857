import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import Address from '../../address/Address';

const S_BillingAddress = styled.div`
  margin-bottom: 16px;
  a.billing-check-box {
    display: flex;
    align-items: center;
    margin-top: 8px;
    text-decoration: none;
    color: #444;
  }
`;

const BillingAddress = ({
  address,
  billingSameAsShipping
}) => {

  return (
    <S_BillingAddress>
      {
        !billingSameAsShipping &&
        <a
          className="billing-check-box"
          href="javascript:void(0)"
          onClick={() => this.setSameAsShipping(true)}>
          <i style={{ marginRight: 8 }} className="fa fa-square-o" />
          {'L\'adresse de facturation est identique à l\'adresse de livraison'}
        </a>
      }
      {billingSameAsShipping && (
        <>
          <i style={{ marginRight: 8 }} className="fa fa-check-square-o" />
          {'L\'adresse de facturation est identique à l\'adresse de livraison'}
        </>
      )}
      {!billingSameAsShipping && (
        <div>
          <div className="">Adresse de facturation</div>
          <Address {...{ address, type: 'billing' }} />
        </div>
      )}
    </S_BillingAddress>
  );
};

export default BillingAddress;
