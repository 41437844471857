import {
  commitMutation,
  graphql,
} from 'react-relay';

const mutation = graphql`
mutation UpdateBillingSameAsShippingMutation($billingSameAsShipping: Boolean) {
  updateBillingSameAsShippingMutation(billingSameAsShipping: $billingSameAsShipping) { 
    ok
    error
    user {
      addresses {
        id
        billingSameAsShipping
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
      }
    }
  }
}
`;

export default (environment, { billingSameAsShipping }, done) => {
  const variables = {
    billingSameAsShipping
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: () => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};
