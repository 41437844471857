import React from 'react';
import PropTypes from 'prop-types';
import { graphql, createFragmentContainer } from 'react-relay';

import { propOr } from 'ramda';
import useOrderStatus from '../../hooks/useOrderStatus';

import {
  dateToString
} from '../../helpers/Format';

const OrderStatusHistoryItem = ({ status, date }) => {
  const orderStatus = useOrderStatus(status);
  return (
    <div className="grid grid-cols-5">
      <div className="order-status-state">
        {orderStatus}
      </div>
      <div className="order-status-date">
        {dateToString(date)}
      </div>
    </div>
  );
};

const OrderStatus = ({ status }) => {
  const history = propOr([], 'history', status);
  return (
    <div>
      {history.map(({ state, date }, index) => (
        <OrderStatusHistoryItem
          key={index}
          status={{ state }}
          date={date}
        />
      )).reverse()}
    </div>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.shape({
    state: PropTypes.oneOf(['new', 'cancelled', 'on_its_way', 'packing', 'closed']),
    history: PropTypes.arrayOf(
      PropTypes.shape({
        state: PropTypes.oneOf(['new', 'cancelled', 'on_its_way', 'packing', 'closed']),
        date: PropTypes.string
      })
    )
  })
};

export default createFragmentContainer(
  OrderStatus,
  {
    status: graphql`
      fragment OrderStatus_status on OrderStatus {
        state
        history {
          state
          date
        }
      }
    `
  }
);