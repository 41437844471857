import React, { useEffect, useState } from 'react';
import ProductListDescription from './ProductListDescription';
import classNames from 'classnames';
import ProductThumb from './ProductThumb';
import More from './More';
import { Back } from '../uikit/UIKit';





const ProductList = ({
  category,
  isAdmin,
  loading,
  loadMore,
  hasMore,
  products,
  productPerRow,
}) => {
  return (
    <React.Fragment>
      <div className="block block-large">
        <ProductListDescription isAdmin={isAdmin} category={category} />
        <div className="block-title">
          <h2>{category.name}</h2>
        </div>
        <div className="block-body">
          <div className="product-list-grid">
            {products.map((product) => (
              <ProductThumb key={product.id} product={product} />
            ))}
          </div>
        </div>
      </div>
      <div className="block-bottom">
        <More
          hasMore={hasMore}
          loading={loading}
          onLoadMore={loadMore}
        />
      </div>
      <Back />
    </React.Fragment>
  );
};

export default ProductList;
