import React from 'react';

import useCart from '../../hooks/useCart';

import { compose, assoc } from 'ramda';

const useProduct = (product) => {

  const { onAddToCart } = useCart();
  const [item, setItem] = React.useState({
    optionKey: '',
    price: product.price,
    stock: product.stock,
    qty: 1,
  });
  const [addingToCart, setAddingToCart] = React.useState(false);

  const onUpdateOptionAttributes = ({ optionKey, price, stock }) => {
    setItem(
      compose(
        assoc('optionKey', optionKey),
        assoc('price', price),
        assoc('stock', stock),
      )(item)
    );
  };

  const onUpdateQty = (qty) => {
    setItem(
      assoc('qty', qty)(item)
    );
  };

  const onAdd = () => {
    const { id } = product;
    const { optionKey, price, qty } = item;
    setAddingToCart(true);
    onAddToCart({ id, price, qty, optionKey }, () => {
      setTimeout(() => {
        setAddingToCart(false);
      }, 300);
    });
  };

  return {
    addingToCart,
    item,
    onAdd,
    onUpdateOptionAttributes,
    onUpdateQty
  };

};

export default useProduct;