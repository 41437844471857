import React from 'react';
import { Link } from 'react-router-dom';
import useSession from '../../../hooks/useSession';

import {
  assoc,
  compose,
  isEmpty
} from 'ramda';

import { Button, Input } from '../../uikit/UIKit';

const SignIn = () => {

  const { onSignIn } = useSession();

  const [state, setState] = React.useState({
    email: '',
    password: '',
    error: ''
  });


  const updateField = (fieldname) => (value) => {
    setState(compose(
      assoc(fieldname, value),
      assoc('showMessage', false),
      assoc('error', '')
    ));
  };

  const submit = (e) => {
    const { email, password } = state;
    console.log(state);
    e.preventDefault();
    onSignIn({ email, password }, (ok, error) => {
      if (!ok) setState(assoc('error', 'Email ou mot de passe incorrect'));
    });
  };

  return (
    <form className="" onSubmit={submit}>
      <h3>{'J\'ai un compte'}</h3>
      <Input title="Votre email" value={state.email} onChange={updateField('email')} />
      <Input title="Votre mot de passe" type="password" value={state.password} onChange={updateField('password')} />
      <Button type="submit" className="mb-4" small={true}>Se connecter</Button>
      <Link to="/account/lostpassword" style={{ marginLeft: '16px' }} className="block-link">{'j\'ai perdu mon mot de passe'}</Link>
      {
        <div>
          {
            !isEmpty(state.error) &&
            <div className="error">
              {state.error}
            </div>
          }
        </div>
      }
    </form>
  );
};


export default SignIn;


