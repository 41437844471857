import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Motion, spring } from 'react-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
class Pictures extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0
    };
  }

  selectPictureIndex(index) {
    this.setState({ selectedIndex: index });
  }

  swipeImage(step) {
    let selectedIndex = this.state.selectedIndex;
    if (step < 0) {
      if (this.state.selectedIndex == 0) {
        selectedIndex = this.props.pictures.length - 1;
      } else {
        selectedIndex--;
      }
    } else {
      if (this.state.selectedIndex == this.props.pictures.length - 1) {
        selectedIndex = 0;
      } else {
        selectedIndex++;
      }
    }
    this.setState({ selectedIndex });
  }

  render() {
    const { pictures } = this.props;
    const { selectedIndex } = this.state;
    return (
      <Motion defaultStyle={{ opacity: 0 }} style={{ opacity: spring(1) }}>
        {({ opacity }) => (
          <div className="product-pictures" style={{ opacity }}>
            {
              this.props.pictures.length > 0 && (
                <a href="javascript:void(0)" className="navigation" onClick={() => this.swipeImage(-1)}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </a>
              )
            }
            {
              _.isEmpty(pictures) &&
              <div style={{ width: '100%', height: '100px', backgroundColor: '#f2f2f2' }} />
            }
            {
              !_.isEmpty(pictures) &&
              <img src={`/image/${pictures[selectedIndex].filename}/400-3-2`} style={{ width: `${window.innerWidth - 64}px` }} />
            }
            {
              this.props.pictures.length > 0 && (
                <a href="javascript:void(0)" className="navigation" onClick={() => this.swipeImage(+1)}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </a>
              )
            }
          </div>
        )}
      </Motion>
    );
  }
}

Pictures.propTypes = {
  pictures: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string
    })
  )
};

export default Pictures;